// import { useEffect, useState } from "react";
// import axios from "axios";
// import {
//   Text,
//   Select,
//   Flex,
//   Paper,
//   Box,
//   ScrollArea,
//   Button,
// } from "@mantine/core";
// import { IconSearch } from "@tabler/icons-react";
// import { useNavigate } from "react-router-dom";
// import { useAuth } from "../../../context/AuthContext";
// // import { format } from "date-fns";

// export default function AssignedDevice() {
//   const navigate = useNavigate();
//   const { user } = useAuth();
//   const [devices, setDevices] = useState([]);
//   const [searchValue, setSearchValue] = useState("");
//   const [deviceUsageHistory, setDeviceUsageHistory] = useState([]);
//   const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
//   useEffect(() => {
//     const fetchDevices = async () => {
//       try {
//         const response = await axios.get(
//           `${API_BASE_URL}/devices/get/user/${user.Data.Email}`
//         );

//         // Format booked devices
//         const bookedDevices = response.data.bookedDevices.map((device) => {
//           const lastBooking = device.bookHistory[device.bookHistory.length - 1];
//           return {
//             category: device.categoryName,
//             name: device.deviceName,
//             did: device.did,
//             platform: device.platformName || "Unknown Platform",
//             status: lastBooking.dateOfReturn ? "returned" : "notReturned",
//             bookingDate: lastBooking.dateofbooking
//               ? new Date(lastBooking.dateofbooking).toLocaleDateString()
//               : "N/A",
//           };
//         });

//         setDevices(bookedDevices);
//       } catch (error) {
//         console.error("Error fetching devices:", error);
//       }
//     };

//     fetchDevices();
//   }, []);

//   const handleSearchChange = (value) => {
//     setSearchValue(value);
//   };

//   const filteredDevices = devices.filter((device) =>
//     device.name.toLowerCase().includes(searchValue.toLowerCase())
//   );

//   return (
//     <div>
//       <Flex justify="space-between" align="center" mr="50px" ml="md">
//         <Text c={"#393939BA"} fw={"bold"} size="20px">
//           Assigned Device
//         </Text>
//       </Flex>
//       <Button bg="#182452" onClick={() => navigate(-1)} m="md">
//         Back
//       </Button>

//       <Paper
//         bg="#FFFFFF"
//         mt="lg"
//         mr="sm"
//         ml="sm"
//         p="lg"
//         style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
//       >
//         <Box
//           p="8px"
//           bg="#182452"
//           style={{
//             border: "1px solid #e0e0e0",
//             borderRadius: "4px",
//             boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//           }}
//         >
//           <Flex>
//             <Text w={"25%"} fw={"bold"} c="#FFFFFF">
//               Category
//             </Text>
//             <Text w={"25%"} fw={"bold"} c="#FFFFFF">
//               Platform
//             </Text>
//             <Text w={"25%"} fw={"bold"} c="#FFFFFF">
//               Device Name
//             </Text>
//             <Text w={"12.5%"} fw={"bold"} c="#FFFFFF">
//               Date of Booking
//             </Text>
//             <Text w={"12.5%"} fw={"bold"} c="#FFFFFF"></Text>
//           </Flex>
//         </Box>
//         <ScrollArea style={{ height: "calc(100vh - 270px)" }} mt="md">
//           {filteredDevices.map((device, index) => (
//             <Box
//               key={index}
//               style={{
//                 border: "1px solid #e0e0e0",
//                 borderRadius: "4px",
//                 cursor: "pointer",
//                 boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//               }}
//               m="10px"
//               p="8px"
//               className="DeviceList"
//             >
//               <Flex justify="space-between" align="center">
//                 <Text w={"25%"} c="#393939BA" fw="600">
//                   {device.category}
//                 </Text>
//                 <Text w={"25%"} c="#393939BA" fw="600">
//                   {device.name}
//                 </Text>
//                 <Text w={"25%"} c="#393939BA" fw="600">
//                   {device.platform}
//                 </Text>
//                 <Text w={"12.5%"} c="#393939BA" fw="600">
//                   {device.bookingDate}
//                 </Text>
//                 <Text w={"12.5%"} c="#393939BA" fw="600">
//                   status
//                 </Text>
//                 <Box w="12.5%" align="end" pr="lg">
//                   <Button
//                     bg="#182452"
//                     onClick={() =>
//                       navigate("/return-device/ReturnScanner", {
//                         state: {
//                           category: device.category,
//                           deviceName: device.name,
//                           platform: device.platform,
//                           startDate: device.bookingDate,
//                           device: device,
//                         },
//                       })
//                     }
//                   >
//                     Return
//                   </Button>
//                 </Box>
//               </Flex>
//             </Box>
//           ))}
//         </ScrollArea>
//       </Paper>
//     </div>
//   );
// }

// import { useEffect, useState } from "react";
// import axios from "axios";
// import { Text, Flex, Paper, Box, ScrollArea, Button } from "@mantine/core";
// import { useNavigate } from "react-router-dom";
// import { useAuth } from "../../../context/AuthContext";

// export default function AssignedDevice() {
//   const navigate = useNavigate();
//   const { user } = useAuth();
//   const [devices, setDevices] = useState([]);
//   const [searchValue, setSearchValue] = useState("");
//   const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

//   useEffect(() => {
//     const fetchDevices = async () => {
//       try {
//         const response = await axios.get(
//           `${API_BASE_URL}/devices/get/user/${user.Data.Email}`
//         );

//         const bookedDevices = response.data.bookedDevices.map((device) => {
//           const lastBooking = device.bookHistory[device.bookHistory.length - 1];
//           return {
//             category: device.categoryName,
//             name: device.deviceName,
//             did: device.did,
//             platform: device.platformName || "Unknown Platform",
//             status: lastBooking.status,
//             bookingDate: lastBooking.dateofbooking
//               ? new Date(lastBooking.dateofbooking).toLocaleDateString()
//               : "N/A",
//           };
//         });

//         setDevices(bookedDevices);
//       } catch (error) {
//         console.error("Error fetching devices:", error);
//       }
//     };

//     fetchDevices();
//   }, [API_BASE_URL, user.Data.Email]);

//   const handleSearchChange = (value) => {
//     setSearchValue(value);
//   };

//   const filteredDevices = devices.filter((device) =>
//     device.name.toLowerCase().includes(searchValue.toLowerCase())
//   );

//   return (
//     <div>
//       <Flex justify="space-between" align="center" mr="50px" ml="md">
//         <Text c={"#393939BA"} fw={"bold"} size="20px">
//           Assigned Device
//         </Text>
//       </Flex>
//       <Button bg="#182452" onClick={() => navigate(-1)} m="md">
//         Back
//       </Button>

//       <Paper
//         bg="#FFFFFF"
//         mt="lg"
//         mr="sm"
//         ml="sm"
//         p="lg"
//         style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
//       >
//         <Box
//           p="8px"
//           bg="#182452"
//           style={{
//             border: "1px solid #e0e0e0",
//             borderRadius: "4px",
//             boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//           }}
//         >
//           <Flex>
//             <Text w={"25%"} fw={"bold"} c="#FFFFFF">
//               Category
//             </Text>
//             <Text w={"25%"} fw={"bold"} c="#FFFFFF">
//               Platform
//             </Text>
//             <Text w={"25%"} fw={"bold"} c="#FFFFFF">
//               Device Name
//             </Text>
//             <Text w={"12.5%"} fw={"bold"} c="#FFFFFF">
//               Date of Booking
//             </Text>
//           </Flex>
//         </Box>
//         <ScrollArea
//           style={{ height: "calc(100vh - 270px)" }}
//           mt="md"
//           type="never"
//         >
//           {filteredDevices.map((device, index) => (
//             <Box
//               key={index}
//               style={{
//                 border: "1px solid #e0e0e0",
//                 borderRadius: "4px",
//                 cursor: "pointer",
//                 boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//               }}
//               m="10px"
//               p="8px"
//               className="DeviceList"
//             >
//               <Flex justify="space-between" align="center">
//                 <Text w={"25%"} c="#393939BA" fw="600">
//                   {device.category}
//                 </Text>
//                 <Text w={"25%"} c="#393939BA" fw="600">
//                   {device.name}
//                 </Text>
//                 <Text w={"25%"} c="#393939BA" fw="600">
//                   {device.platform}
//                 </Text>
//                 <Text w={"12.5%"} c="#393939BA" fw="600">
//                   {device.bookingDate}
//                 </Text>
//                 <Box w="12.5%" align="end" pr="lg">
//                   {device.status === "booked" && (
//                     <Button
//                       bg="#182452"
//                       onClick={() =>
//                         navigate("/return-device/ReturnScanner", {
//                           state: {
//                             category: device.category,
//                             deviceName: device.name,
//                             platform: device.platform,
//                             startDate: device.bookingDate,
//                             device: device,
//                           },
//                         })
//                       }
//                     >
//                       Return
//                     </Button>
//                   )}
//                   {device.status === "return rejected" && (
//                     <Button
//                       bg="red"
//                       onClick={() =>
//                         navigate(
//                           `/return-device/AssignedDevice/ViewRejectedDevice/${device.did}`
//                         )
//                       }
//                     >
//                       Rejected
//                     </Button>
//                   )}
//                 </Box>
//               </Flex>
//             </Box>
//           ))}
//         </ScrollArea>
//       </Paper>
//     </div>
//   );
// }

import { useEffect, useState } from "react";
import axios from "axios";
import {
  Text,
  Flex,
  Paper,
  Box,
  ScrollArea,
  Button,
  Modal,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";

export default function AssignedDevice() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [devices, setDevices] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [modalOpened, setModalOpened] = useState(false);
  const [rejectedReason, setRejectedReason] = useState("");
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [selectedDevice, setSelectedDevice] = useState("");

  // useEffect(() => {
  //   const fetchDevices = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${API_BASE_URL}/devices/get/user/${user.Data.Email}`
  //       );

  //       const bookedDevices = response.data.bookedDevices.map((device) => {
  //         const lastBooking = device.bookHistory[device.bookHistory.length - 1];
  //         return {
  //           category: device.categoryName,
  //           name: device.deviceName,
  //           did: device.did,
  //           platform: device.platformName || "Unknown Platform",
  //           status: lastBooking.status,
  //           bookingDate: lastBooking.dateofbooking
  //             ? new Date(lastBooking.dateofbooking).toLocaleDateString()
  //             : "N/A",
  //           rejectionReason:
  //             lastBooking.rejectionReason || "No reason provided",
  //           projectName: projectName,
  //         };
  //       });

  //       setDevices(bookedDevices);
  //     } catch (error) {
  //       console.error("Error fetching devices:", error);
  //     }
  //   };

  //   fetchDevices();
  // }, [API_BASE_URL, user.Data.Email]);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/devices/get/user/${user.Data.Email}`
        );

        const bookedDevices = response.data.bookedDevices.map((device) => {
          const lastBooking = device.bookHistory[device.bookHistory.length - 1];
          return {
            category: device.categoryName,
            name: device.deviceName,
            did: device.did,
            platform: device.platformName || "Unknown Platform",
            status: lastBooking.status,
            bookingDate: lastBooking.dateofbooking
              ? new Date(lastBooking.dateofbooking).toLocaleDateString()
              : "N/A",
            rejectionReason:
              lastBooking.rejectionReason || "No reason provided",
            projectName: lastBooking.projectName || device.projectName,
          };
        });

        setDevices(bookedDevices);
      } catch (error) {
        console.error("Error fetching devices:", error);
      }
    };

    fetchDevices();
  }, [API_BASE_URL, user.Data.Email]);

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  const handleRejectedClick = (device) => {
    setSelectedDevice(device.did);
    setRejectedReason(device.rejectionReason);
    setModalOpened(true);
  };

  const filteredDevices = devices.filter((device) =>
    device.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div>
      <Flex justify="space-between" align="center" mr="50px" ml="md">
        <Text c={"#393939BA"} fw={"bold"} size="20px">
          Assigned Device
        </Text>
      </Flex>
      <Button bg="#182452" onClick={() => navigate(-1)} m="md">
        Back
      </Button>

      <Paper
        bg="#FFFFFF"
        mt="lg"
        mr="sm"
        ml="sm"
        p="lg"
        style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
      >
        <Box
          p="8px"
          bg="#182452"
          style={{
            border: "1px solid #e0e0e0",
            borderRadius: "4px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Flex>
            <Text w={"25%"} fw={"bold"} c="#FFFFFF">
              Category
            </Text>
            <Text w={"25%"} fw={"bold"} c="#FFFFFF">
              Platform
            </Text>
            <Text w={"25%"} fw={"bold"} c="#FFFFFF">
              Device Name
            </Text>
            <Text w={"12.5%"} fw={"bold"} c="#FFFFFF">
              Date of Booking
            </Text>
          </Flex>
        </Box>
        <ScrollArea
          style={{ height: "calc(100vh - 270px)" }}
          mt="md"
          type="never"
        >
          {filteredDevices.map((device, index) => (
            <Box
              key={index}
              style={{
                border: "1px solid #e0e0e0",
                borderRadius: "4px",
                cursor: "pointer",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
              m="10px"
              p="8px"
              className="DeviceList"
            >
              <Flex justify="space-between" align="center">
                <Text w={"25%"} c="#393939BA" fw="600">
                  {device.category}
                </Text>
                <Text w={"25%"} c="#393939BA" fw="600">
                  {device.name}
                </Text>
                <Text w={"25%"} c="#393939BA" fw="600">
                  {device.platform}
                </Text>
                <Text w={"12.5%"} c="#393939BA" fw="600">
                  {device.bookingDate}
                </Text>
                <Box w="12.5%" align="end" pr="lg">
                  {device.status === "booked" && (
                    <Button
                      bg="#182452"
                      onClick={() =>
                        navigate("/return-device/ReturnScanner", {
                          state: {
                            category: device.category,
                            deviceName: device.name,
                            platform: device.platform,
                            startDate: device.bookingDate,
                            did: device.did,
                            device: device,
                            projectName: device.projectName,
                          },
                        })
                      }
                    >
                      Return
                    </Button>
                  )}
                  {device.status === "return rejected" && (
                    <Button
                      bg="red"
                      onClick={() => handleRejectedClick(device)}
                    >
                      Rejected
                    </Button>
                  )}
                </Box>
              </Flex>
            </Box>
          ))}
        </ScrollArea>
      </Paper>

      <Modal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        centered
        size="50%"
      >
        <Text c="#393939BA" fw="600" bg="#EBEBEB" p="sm" align="center">
          Rejected Reason
        </Text>
        <Paper shadow="md" p="md" radius="xs">
          <Text c="#393939BA" fw="600">
            {rejectedReason}
          </Text>
        </Paper>

        <Box mt={"md"}>
          <Text c="#393939BA" fw="600">
            Device ID: {selectedDevice}
          </Text>
        </Box>
      </Modal>
    </div>
  );
}
