import { Box, Flex, Paper, Text, Tabs, Input } from "@mantine/core";
import React, { useState } from "react";
import NewRequest from "../../components/pagesComponent/RequestComponents/NewRequest";
import ApprovedRequest from "../../components/pagesComponent/RequestComponents/ApprovedRequest";
import RejectedRequest from "../../components/pagesComponent/RequestComponents/RejectedRequest";
import { IconSearch } from "@tabler/icons-react";

export default function Request() {
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <div>
      <Box px="md">
        <Flex justify="space-between">
          <Text c={"#393939BA"} fw={"bold"} size="20px" mt="sm">
            Requests
          </Text>
          <Input
            placeholder="Search"
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.currentTarget.value)}
            mb="md"
            rightSection={<IconSearch />}
          />
        </Flex>
        <Paper bg="#FFFFFF80" my="md" withBorder shadow="xs">
          <Tabs
            variant="pills"
            // radius="xl"
            defaultValue="new"
            color="#182452"
            p="lg"
          >
            <Tabs.List>
              <Tabs.Tab value="new">
                <Text size="18px">New Requests</Text>
              </Tabs.Tab>
              <Tabs.Tab value="approved">
                <Text size="18px">Approved</Text>
              </Tabs.Tab>
              <Tabs.Tab value="rejected">
                <Text size="18px">Rejected</Text>
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="new">
              <Box mt="xl">
                <NewRequest searchQuery={searchQuery} />
              </Box>
            </Tabs.Panel>

            <Tabs.Panel value="approved" mt="xl">
              <ApprovedRequest searchQuery={searchQuery} />
            </Tabs.Panel>

            <Tabs.Panel value="rejected" mt="xl">
              <RejectedRequest searchQuery={searchQuery} />
            </Tabs.Panel>
          </Tabs>
        </Paper>
      </Box>
    </div>
  );
}
