import { useState, useEffect } from "react";
import { Button, Flex, Input, Modal, Paper, Text } from "@mantine/core";
import { IconEdit, IconPlus, IconTrash } from "@tabler/icons-react";
import axios from "axios";
import { useDisclosure } from "@mantine/hooks";

export default function Project() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [assetOwners, setAssetOwners] = useState([]);
  const [opened, { open, close }] = useDisclosure(false);
  const [newProjectName, setNewProjectName] = useState("");
  const [currentProjectName, setCurrentProjectName] = useState("");
  const [editProjectName, setEditProjectName] = useState("");
  const [deleteProjectName, setDeleteProjectName] = useState("");
  const [
    deleteProjectModalOpened,
    { open: openProjectOwnerModal, close: closeProjectOwnerModal },
  ] = useDisclosure(false);

  const [
    editProjectModalOpened,
    { open: openEditProjectModal, close: closeEditProjectModal },
  ] = useDisclosure(false);

  const fetchProject = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getallprojects`);
      setAssetOwners(response.data);
    } catch (error) {
      console.error("Error fetching asset owners:", error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchProject();
    }, 300); // 2000 milliseconds = 2 seconds

    return () => clearTimeout(timer); // Cleanup the timeout on component unmount
  }, []);

  const handleAddProject = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/project/add`, {
        projectName: newProjectName,
      });
      console.log("Owner added successfully:", response.data);
      fetchProject();
      close();
      setNewProjectName("");
    } catch (error) {
      console.error("Failed to add owner:", error);
    }
  };

  const handleEditProjectModal = (owner) => {
    setCurrentProjectName(owner.projectName);
    setEditProjectName(owner.projectName);
    openEditProjectModal();
  };

  const handleEditProject = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/project/update`, {
        projectName: currentProjectName,
        newProjectName: editProjectName,
      });
      console.log("Owner updated successfully:", response.data);
      fetchProject();
      closeEditProjectModal();
    } catch (error) {
      console.error("Failed to update owner:", error);
    }
  };

  const handleDeleteProjectModal = (owner) => {
    setDeleteProjectName(owner.projectName);
    openProjectOwnerModal();
  };

  const handleDeleteProject = async () => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/project/delete`, {
        data: { projectName: deleteProjectName },
      });
      console.log("Owner deleted successfully:", response.data);
      fetchProject();
      closeProjectOwnerModal();
    } catch (error) {
      console.error("Failed to delete owner:", error);
    }
  };

  return (
    <div>
      <Flex justify="space-between" p="lg" align="center">
        <Text c="#393939BA" fw="bold" size="18px">
          Projects
        </Text>
        <IconPlus
          style={{
            cursor: "pointer",
            width: 30,
            height: 30,
          }}
          color="#393939BA"
          onClick={open}
        />
      </Flex>
      <Paper bg="#FFFFFF" p="lg">
        {assetOwners.map((owner) => (
          <Flex key={owner._id} align="center" mb="sm" justify="space-between">
            <Text c="#393939BA" fw="bold">
              {owner.projectName}
            </Text>
            <Flex gap="lg">
              <IconEdit
                color="#393939BA"
                style={{ cursor: "pointer", marginLeft: "auto" }}
                onClick={() => handleEditProjectModal(owner)}
              />
              <IconTrash
                color="#393939BA"
                style={{ cursor: "pointer" }}
                onClick={() => handleDeleteProjectModal(owner)}
              />
            </Flex>
          </Flex>
        ))}
      </Paper>
      <Modal opened={opened} onClose={close} centered>
        <Text mb="sm" c="#393939BA" fw="bold">
          Add New Project Name
        </Text>
        <Input
          value={newProjectName}
          onChange={(e) => setNewProjectName(e.target.value)}
        />
        <Flex w="100%" justify="flex-end" mt="lg">
          <Button bg="#182452" onClick={handleAddProject}>
            Add
          </Button>
        </Flex>
      </Modal>
      <Modal
        opened={editProjectModalOpened}
        onClose={closeEditProjectModal}
        centered
        withCloseButton
      >
        <Text mb="sm" c="#393939BA" fw="bold">
          Edit Project Name
        </Text>
        <Input
          value={editProjectName}
          onChange={(e) => setEditProjectName(e.target.value)}
        />
        <Flex w="100%" justify="flex-end" mt="lg">
          <Button bg="#182452" onClick={handleEditProject}>
            Edit
          </Button>
        </Flex>
      </Modal>
      <Modal
        opened={deleteProjectModalOpened}
        onClose={closeProjectOwnerModal}
        centered
        withCloseButton
      >
        <Text mb="sm" c="#393939BA" fw="bold">
          Are you sure you want to delete the owner "{deleteProjectName}"?
        </Text>
        <Flex w="100%" justify="flex-end" mt="lg" gap="lg">
          <Button ml="sm" bg="#182452" onClick={closeProjectOwnerModal}>
            Cancel
          </Button>
          <Button bg="red" onClick={handleDeleteProject}>
            Delete
          </Button>
        </Flex>
      </Modal>
    </div>
  );
}
