import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Box, useMantineTheme } from "@mantine/core";
import { NavbarSegmented } from "./components/sidebar/NavbarNested/NavbarNested";
import BookDevice from "./pages/book-device/BookDevice";
import MyDevice from "./pages/my-device/MyDevice";
import DeviceMaster from "./pages/device-master/DeviceMaster";
import BookDeviceList from "./components/pagesComponent/BookDeviceList";
import ScanDevice from "./components/pagesComponent/ScanDevice";
import "./App.css";
import "@mantine/core/styles.css";
import DeviceHistory from "./components/pagesComponent/DeviceHistroy";
import Login from "./pages/Login/Login";
import AddCategories from "./components/pagesComponent/AddCategory";
import ReturnScanner from "./components/pagesComponent/ReturnSacnner";
import Request from "./pages/request/Request";
import ViewRequest from "./components/pagesComponent/RequestComponents/ViewRequest";
import ViewRejectedRequest from "./components/pagesComponent/RequestComponents/ViewRejectedRequest";
import AssignedDevice from "./components/pagesComponent/MyDevice/AssignedDevice";
import DeviceHistroy from "./components/pagesComponent/MyDevice/DeviceHsitory";
import { useAuth } from "./context/AuthContext";
import Dashboard from "./pages/dashboard/Dashboard";
import ViewRejectedDevice from "./components/pagesComponent/MyDevice/ViewRejectedDevice";
import DamagedDeviceList from "./components/pagesComponent/DashboardComponents/DamagedDeviceList";
import ViewDamagedDevice from "./components/pagesComponent/DashboardComponents/ViewDamagedDevice";

function App() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const theme = useMantineTheme();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [userRole, setUserRole] = useState("");
  const [userName, setUserName] = useState("");
  const { user } = useAuth();
  // Check localStorage on app load
  useEffect(() => {
    const storedAuth = JSON.parse(localStorage.getItem("auth"));
    if (storedAuth && storedAuth.isAuthenticated) {
      setIsAuthenticated(true);
      setUserRole(storedAuth.userRole);
      setUserName(storedAuth.userName);
    }
  }, []);

  // Update localStorage when authentication state changes
  useEffect(() => {
    localStorage.setItem(
      "auth",
      JSON.stringify({ isAuthenticated, userRole, userName })
    );
  }, [isAuthenticated, userRole, userName]);

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme.colorScheme);
  }, [theme.colorScheme]);

  // const handleLoginSuccess = (data) => {
  //   setIsAuthenticated(true);
  //   setUserRole(data.Data.Role);
  //   setUserName(`${data.Data.First_Name} ${data.Data.Last_Name}`);
  // };
  const handleLoginSuccess = (data) => {
    setIsAuthenticated(true);
    setUserRole(data.Data.Role);
    setUserName(`${data.Data.First_Name} ${data.Data.Last_Name}`);

    // Make API call to add user
    const userData = {
      First_Name: data.Data.First_Name,
      Last_Name: data.Data.Last_Name,
      Email: data.Data.Email,
      Designation: data.Data.Designation,
      Role: data.Data.Role,
    };

    fetch(`${API_BASE_URL}/user/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("User added successfully:", data);
        // Optionally handle success response
      })
      .catch((error) => {
        console.error("Error adding user:", error);
        // Optionally handle error
      });
  };

  const handleLogOut = () => {
    setIsAuthenticated(false);
    setUserRole(null);
    setUserName(null);
    localStorage.removeItem("auth"); // Clear auth state from localStorage
  };

  if (!isAuthenticated) {
    return (
      <Router>
        <Routes>
          <Route path="" element={<Navigate to="/login" />} />
          <Route
            path="/login"
            element={<Login onLoginSuccess={handleLoginSuccess} />}
          />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    );
  }

  return (
    <Router>
      <div
        style={{
          display: "flex",
          padding: 10,
          backgroundColor: "#EFEFEF",
        }}
      >
        <NavbarSegmented
          role={userRole}
          userName={userName}
          onLogOut={handleLogOut}
        />
        <Box style={{ flex: 1 }} bg={"#EFEFEF"} m={10}>
          <Routes>
            <Route path="*" element={<Navigate to="/book-device" />} />
            <Route path="/" element={<Navigate to="/book-device" />} />
            <Route path="/book-device" element={<BookDevice />} />
            <Route path="/return-device" element={<MyDevice />} />
            <Route
              path="/return-device/ReturnScanner"
              element={<ReturnScanner />}
            />
            <Route
              path="/return-device/AssignedDevice"
              element={<AssignedDevice />}
            />
            <Route
              path="/return-device/DeviceHistroy"
              element={<DeviceHistroy />}
            />
            <Route
              path="/book-device/bookDeviceList/:selectedCategory/:platform/:deviceName"
              element={<BookDeviceList />}
            />
            <Route
              path="/book-device/bookDeviceList/scanDevice/:selectedCategory/:platform/:deviceName"
              element={<ScanDevice />}
            />
            {userRole === "Admin" && (
              <>
                <Route path="/device-master" element={<DeviceMaster />} />
                <Route
                  path="/device-master/device-history/:deviceId"
                  element={<DeviceHistory />}
                />
                <Route
                  path="/device-master/add-category"
                  element={<AddCategories />}
                />
                <Route path="/Requests" element={<Request />} />
                <Route
                  path="/Requests/ViewRequest/:did"
                  element={<ViewRequest />}
                />
                <Route
                  path="/Requests/ViewRejectedRequest/:did"
                  element={<ViewRejectedRequest />}
                />
                <Route path="/Requests" element={<Request />} />
              </>
            )}
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route
              path="/Dashboard/DamagedDeviceList"
              element={<DamagedDeviceList />}
            />
            <Route
              path="/Dashboard/DamagedDeviceList/ViewDamagedDevice/:did"
              element={<ViewDamagedDevice />}
            />
            ViewDamagedDevice
          </Routes>
        </Box>
      </div>
    </Router>
  );
}

export default App;
