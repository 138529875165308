import {
  Box,
  Button,
  Divider,
  Flex,
  Paper,
  Table,
  Text,
  Textarea,
  ScrollArea,
} from "@mantine/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function ViewRequest() {
  const navigate = useNavigate();
  const { did } = useParams();
  const [requestData, setRequestData] = useState(null);
  const [reason, setReason] = useState("");
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  useEffect(() => {
    axios
      .get(
        `${API_BASE_URL}/devices/pending-requests`
      )
      .then((response) => {
        const data = response.data;
        const request = data.find((item) => item.did === did);
        setRequestData(request);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [did]);

  if (!requestData) {
    return <div>Loading...</div>;
  }

  const approveRequest = () => {
    const apiUrl =
      requestData.bookHistory.status === "pending booking"
        ? `${API_BASE_URL}/devices/approve-book`
        : `${API_BASE_URL}/devices/approve-return`;

    const requestBody = {
      did: requestData.did,
      role: "Admin",
    };

    axios
      .post(apiUrl, requestBody)
      .then((response) => {
        console.log("Request processed:", response.data);
        // Optionally, navigate back or update the UI to reflect the approval
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error processing request:", error);
      });
  };

  const {
    categoryName,
    platformName,
    deviceName,
    did: deviceId,
    imei1,
    imei12,
    processor,
    genration,
    screenSize,
    ram,
    storage,
    macAddress,
    ownerName,
    ownershipType,
    cost,
    added_by,
    bookHistory,
    pendingProjectName,
    projectName,
  } = requestData;

  const rejectRequest = () => {
    const apiUrl =
      requestData.bookHistory.status === "pending booking"
        ? `${API_BASE_URL}/devices/reject-book`
        : `${API_BASE_URL}/devices/reject-return`;

    const requestBody = {
      did: requestData.did,
      role: "Admin",
      reason: reason,
    };

    axios
      .post(apiUrl, requestBody)
      .then((response) => {
        console.log("Request rejected:", response.data);
        // Optionally, navigate back or update the UI to reflect the rejection
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error rejecting request:", error);
      });
  };

  return (
    <div>
      <Button bg="#182452" m="md" onClick={() => navigate(-1)}>
        Back
      </Button>
      <Text mt="md" c={"#393939BA"} fw={"bold"} size="20px" ml="md">
        View Request
      </Text>
      <ScrollArea h="calc(100vh - 170px)">
        <Paper m="md" style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
          <Flex
            // justify="space-between"
            p="30px"
            // align="center"
            direction="column"
          >
            <Flex direction="row" gap="lg" justify="space-between">
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="33%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Category:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {categoryName}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="33%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Paltform:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {platformName}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="33%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Device Name:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceName}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Flex direction="row" gap="lg" justify="space-between" mt="md">
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="33%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Serial Number:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {did}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="33%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    IMEI 1:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {imei1}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="33%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    IMEI 2:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {imei12}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Flex direction="row" gap="lg" justify="space-between" mt="md">
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="33%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Processor:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {processor}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                w="33%"
                p="xs"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Genration:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {genration ? genration : "-----"}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="33%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Screensize:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {screenSize}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Flex direction="row" gap="lg" justify="space-between" mt="md">
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="33%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Ram:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {ram}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="33%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Storage:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {storage}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="33%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Mac Address:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {macAddress}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Flex direction="row" gap="lg" justify="space-between" mt="md">
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="33%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Asset Owner:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {ownerName}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="33%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Ownership Type:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {ownershipType}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="33%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Cost:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {cost}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Divider my="md" mt="lg" size="2px" />
            <Flex
              direction="row"
              gap="xl"
              justify="space-between"
              align="center"
            >
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="33%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Requested By:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {bookHistory.userid}
                  </Text>
                </Flex>
              </Box>

              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="33%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Requested On:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {new Date(bookHistory.dateOfRequest).toLocaleDateString()}
                  </Text>
                </Flex>
              </Box>

              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="33%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Requested for:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {bookHistory.status === "pending booking"
                      ? "Book"
                      : "Return"}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Flex
              direction="row"
              gap="lg"
              justify="space-between"
              align="end"
              mt="md"
            >
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="32%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Project Name:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {pendingProjectName ? pendingProjectName : projectName}
                  </Text>
                </Flex>
              </Box>
              <Button bg="#182452" onClick={approveRequest} mr="lg">
                Approve
              </Button>
            </Flex>
          </Flex>
        </Paper>
        {requestData.bookHistory.status === "pending return" && (
          <Paper
            p="lg"
            m="md"
            style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
          >
            <Text c={"#393939BA"} fw={"bold"} size="20px">
              Comment
            </Text>
            <Textarea
              autosize
              minRows={2}
              mt="md"
              value={reason}
              onChange={(e) => setReason(e.currentTarget.value)}
            />
          </Paper>
        )}
        <Flex justify="flex-end" mt="lg" w="100%" pr="xl">
          <Button color="red" onClick={rejectRequest}>
            Reject
          </Button>
        </Flex>
      </ScrollArea>
    </div>
  );
}
