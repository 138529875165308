import React, { useState, useEffect } from "react";
import {
  Text,
  Flex,
  Paper,
  Box,
  ScrollArea,
  Button,
  Loader,
  Pagination,
} from "@mantine/core";
import { useParams, useNavigate } from "react-router-dom";

export default function BookDeviceList() {
  const navigate = useNavigate();
  const { selectedCategory, platform, deviceName } = useParams();
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust the number of items per page as needed
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchDeviceDetails = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/devices/get/cat/${selectedCategory}/${platform}/${deviceName}`
        );
        const data = await response.json();
        setDevices(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching device details:", error);
        setLoading(false);
      }
    };

    fetchDeviceDetails();
  }, [selectedCategory, platform, deviceName]);

  const totalItems = devices.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentItems = devices.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <Flex justify="space-between" align="center" mr="50px" ml="md">
        <Button bg="#182452" onClick={() => navigate(-1)}>
          Back
        </Button>
      </Flex>
      <Paper
        bg="#FFFFFF"
        mt="lg"
        mr="sm"
        ml="sm"
        p="lg"
        style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
      >
        <Flex align="center" mb="lg" pr="lg" pl="lg">
          <Flex w="50%" gap={"xl"}>
            <Box
              style={{
                border: "1px solid #80808033",
                borderRadius: "5px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
              bg="#F6F6F6"
              p="sm"
            >
              <Flex gap="xs">
                <Text c="#484848" fw="500">
                  Platform:{" "}
                </Text>
                <Text c="#DC8585" fw="bold">
                  {platform}
                </Text>
              </Flex>
            </Box>
            <Box
              style={{
                border: "1px solid #80808033",
                borderRadius: "5px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
              bg="#F6F6F6"
              p="sm"
            >
              <Flex gap="xs">
                <Text c="#484848" fw="500">
                  Device Name:{" "}
                </Text>
                <Text c="#DC8585" fw="bold">
                  {deviceName}
                </Text>
              </Flex>
            </Box>
          </Flex>
          <Flex w="50%" justify="flex-end" gap="lg">
            <Button
              bg="#182452"
              onClick={() =>
                navigate(
                  `/book-device/bookDeviceList/scanDevice/${selectedCategory}/${platform}/${deviceName}`
                )
              }
            >
              Book Device
            </Button>
          </Flex>
        </Flex>
        <Box
          m="10px"
          p="10px"
          bg="#182452"
          style={{
            border: "1px solid #e0e0e0",
            borderRadius: "4px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Flex>
            <Text w={"28%"} fw={"bold"} c="#FFFFFF">
              Device ID
            </Text>
            <Text w={"26%"} fw={"bold"} c="#FFFFFF" px="10px">
              Assignee Name
            </Text>
            <Text w={"26%"} fw={"bold"} c="#FFFFFF" px="10px">
              Date of Booking
            </Text>
            <Text w={"20%"} fw={"bold"} c="#FFFFFF" px="10px">
              Status
            </Text>
          </Flex>
        </Box>
        {loading ? (
          <Flex
            justify="center"
            align="center"
            style={{ height: "calc(100vh - 210px)" }}
          >
            <Loader size={50} color="#182452" />
          </Flex>
        ) : (
          <ScrollArea style={{ height: "calc(100vh - 290px)" }} type="never">
            {currentItems.map((device, index) => (
              <Box
                key={index}
                style={{
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                  cursor: "pointer",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                m="10px"
                mr="sm"
                ml="sm"
                className="DeviceList"
              >
                <Flex justify="space-between">
                  <Box w={"28%"}>
                    <Text
                      c="#FFFFFF"
                      fw="bold"
                      bg={device.status === "available" ? "green" : "red"}
                      p="10px"
                      w="100%"
                    >
                      {device.did}
                    </Text>
                  </Box>
                  <Text w={"26%"} p="10px" fw={"600"} c="#6D6D6D" ml={"xs"}>
                    {device.status !== "available" &&
                    device.bookHistory.length > 0
                      ? `${
                          device.bookHistory[device.bookHistory.length - 1]
                            .First_Name
                        } ${
                          device.bookHistory[device.bookHistory.length - 1]
                            .Last_Name
                        }`
                      : "-------"}
                  </Text>
                  <Text w={"26%"} p="10px" fw={"600"} c="#6D6D6D">
                    {device.status === "booked" && device.bookHistory.length > 0
                      ? new Date(
                          device.bookHistory[
                            device.bookHistory.length - 1
                          ].dateofbooking
                        ).toLocaleDateString("en-IN", {
                          timeZone: "Asia/Kolkata",
                        })
                      : "-------"}
                  </Text>
                  <Text w={"20%"} p="10px" fw={"600"} c="#6D6D6D">
                    <span
                      style={{
                        color: device.status === "available" ? "green" : "red",
                      }}
                    >
                      {device.status}
                    </span>
                  </Text>
                </Flex>
              </Box>
            ))}
            <Flex justify="center" mt="lg">
              <Pagination
                page={currentPage}
                onChange={handlePageChange}
                total={totalPages}
                color="#182452"
              />
            </Flex>
          </ScrollArea>
        )}
      </Paper>
    </div>
  );
}
