// import React, { useEffect, useState } from "react";
// import {
//   Text,
//   Flex,
//   Paper,
//   Box,
//   Button,
//   Modal,
//   Loader,
//   ScrollArea,
// } from "@mantine/core";
// import { useNavigate, useParams } from "react-router-dom";
// import HistoryTable from "./HistoryTable";
// import { useDisclosure } from "@mantine/hooks";
// import EditDeviceModal from "./EditDeviceModal";
// import "./DeviceHistory.css";

// export default function DeviceHistory() {
//   const navigate = useNavigate();
//   const { deviceId } = useParams();
//   const [deviceHistory, setDeviceHistory] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [editModalOpened, { open: openEditModal, close: closeEditModal }] =
//     useDisclosure(false);
//   const [
//     deleteModalOpened,
//     { open: openDeleteModal, close: closeDeleteModal },
//   ] = useDisclosure(false);

//   useEffect(() => {
//     setLoading(true);
//     fetch(
//       `https://d13d04nxcwaid6.cloudfront.net/api/v1/admin/devices/get/device/${deviceId}`
//     )
//       .then((response) => response.json())
//       .then((data) => {
//         setDeviceHistory(data);
//       })
//       .catch((error) => console.error("Error fetching data:", error))
//       .finally(() => setLoading(false));
//   }, [deviceId]);

//   const handleDeleteDevice = () => {
//     fetch(`https://d13d04nxcwaid6.cloudfront.net/api/v1/admin/devices/delete`, {
//       method: "DELETE",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         did: deviceHistory.did, // Assuming deviceHistory contains the device ID
//       }),
//     })
//       .then((response) => {
//         if (response.ok) {
//           navigate(-1);
//         } else {
//           throw new Error("Failed to delete device");
//         }
//       })
//       .catch((error) => console.error("Error deleting device:", error));
//   };

//   const handleMarkAsDamaged = () => {
//     fetch(`https://d13d04nxcwaid6.cloudfront.net/api/v1/admin/devices/damage`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         did: deviceHistory.did,
//         role: "Admin",
//       }),
//     })
//       .then((response) => {
//         if (response.ok) {
//           console.log("Device marked as damaged successfully.");
//           // Optionally handle any additional UI updates or state changes here
//         } else {
//           throw new Error("Failed to mark device as damaged");
//         }
//       })
//       .catch((error) =>
//         console.error("Error marking device as damaged:", error)
//       );
//   };

//   if (loading) {
//     return (
//       <Flex justify="center" align="center" style={{ height: "100vh" }}>
//         <Loader size={50} color="#182452" />
//       </Flex>
//     );
//   }

//   if (!deviceHistory) {
//     return (
//       <Text align="center" c="red">
//         No history found for the selected device.
//       </Text>
//     );
//   }

//   return (
//     <div>
//       <Flex justify="space-between" align="center" mr="50px" ml="md">
//         <Button bg="#182452" onClick={() => navigate(-1)}>
//           Back
//         </Button>
//       </Flex>
//       <ScrollArea style={{ height: "calc(100vh - 80px)" }}>
//         <Paper m="md" style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
//           <Flex
//             // justify="space-between"
//             p="30px"
//             // align="center"
//             direction="column"
//           >
//             <Flex direction="row" gap="lg" justify="space-between">
//               <Box
//                 style={{
//                   border: "1px solid #80808033",
//                   borderRadius: "5px",
//                   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                 }}
//                 bg="#F6F6F6"
//                 p="xs"
//                 w="30%"
//               >
//                 <Flex gap="xs">
//                   <Text c="#484848" fw="500" className="text">
//                     Category:{" "}
//                   </Text>
//                   <Text c="#DC8585" fw="bold" className="text">
//                     {deviceHistory.categoryName}
//                   </Text>
//                 </Flex>
//               </Box>
//               <Box
//                 style={{
//                   border: "1px solid #80808033",
//                   borderRadius: "5px",
//                   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                 }}
//                 bg="#F6F6F6"
//                 p="xs"
//                 w="30%"
//               >
//                 <Flex gap="xs">
//                   <Text c="#484848" fw="500" className="text">
//                     Device Name:{" "}
//                   </Text>
//                   <Text c="#DC8585" fw="bold" className="text">
//                     {deviceHistory.deviceName}
//                   </Text>
//                 </Flex>
//               </Box>
//               <Box
//                 style={{
//                   border: "1px solid #80808033",
//                   borderRadius: "5px",
//                   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                 }}
//                 bg="#F6F6F6"
//                 p="xs"
//                 w="30%"
//               >
//                 <Flex gap="xs">
//                   <Text c="#484848" fw="500" className="text">
//                     Paltform:{" "}
//                   </Text>
//                   <Text c="#DC8585" fw="bold" className="text">
//                     {deviceHistory.platformName}
//                   </Text>
//                 </Flex>
//               </Box>
//             </Flex>
//             <Flex direction="row" gap="lg" justify="space-between" mt="md">
//               <Box
//                 style={{
//                   border: "1px solid #80808033",
//                   borderRadius: "5px",
//                   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                 }}
//                 bg="#F6F6F6"
//                 p="xs"
//                 w="30%"
//               >
//                 <Flex gap="xs">
//                   <Text c="#484848" fw="500" className="text">
//                     Serial Number:{" "}
//                   </Text>
//                   <Text c="#DC8585" fw="bold" className="text">
//                     {deviceHistory.did}
//                   </Text>
//                 </Flex>
//               </Box>
//               <Box
//                 style={{
//                   border: "1px solid #80808033",
//                   borderRadius: "5px",
//                   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                 }}
//                 bg="#F6F6F6"
//                 p="xs"
//                 w="30%"
//               >
//                 <Flex gap="xs">
//                   <Text c="#484848" fw="500" className="text">
//                     IMEI 1:{" "}
//                   </Text>
//                   <Text c="#DC8585" fw="bold" className="text">
//                     {deviceHistory.imei1}
//                   </Text>
//                 </Flex>
//               </Box>
//               <Box
//                 style={{
//                   border: "1px solid #80808033",
//                   borderRadius: "5px",
//                   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                 }}
//                 bg="#F6F6F6"
//                 p="xs"
//                 w="30%"
//               >
//                 <Flex gap="xs">
//                   <Text c="#484848" fw="500" className="text">
//                     IMEI 2:{" "}
//                   </Text>
//                   <Text c="#DC8585" fw="bold" className="text">
//                     {deviceHistory.imei12}
//                   </Text>
//                 </Flex>
//               </Box>
//             </Flex>
//             <Flex direction="row" gap="lg" justify="space-between" mt="md">
//               <Box
//                 style={{
//                   border: "1px solid #80808033",
//                   borderRadius: "5px",
//                   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                 }}
//                 bg="#F6F6F6"
//                 p="xs"
//                 w="30%"
//               >
//                 <Flex gap="xs">
//                   <Text c="#484848" fw="500" className="text">
//                     Processor:{" "}
//                   </Text>
//                   <Text c="#DC8585" fw="bold" className="text">
//                     {deviceHistory.processor}
//                   </Text>
//                 </Flex>
//               </Box>
//               <Box
//                 style={{
//                   border: "1px solid #80808033",
//                   borderRadius: "5px",
//                   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                 }}
//                 bg="#F6F6F6"
//                 w="30%"
//                 p="xs"
//               >
//                 <Flex gap="xs">
//                   <Text c="#484848" fw="500" className="text">
//                     Genration:{" "}
//                   </Text>
//                   <Text c="#DC8585" fw="bold" className="text">
//                     {deviceHistory.genration}
//                   </Text>
//                 </Flex>
//               </Box>
//               <Box
//                 style={{
//                   border: "1px solid #80808033",
//                   borderRadius: "5px",
//                   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                 }}
//                 bg="#F6F6F6"
//                 p="xs"
//                 w="30%"
//               >
//                 <Flex gap="xs">
//                   <Text c="#484848" fw="500" className="text">
//                     Screensize:{" "}
//                   </Text>
//                   <Text c="#DC8585" fw="bold" className="text">
//                     {deviceHistory.screenSize}
//                   </Text>
//                 </Flex>
//               </Box>
//             </Flex>
//             <Flex direction="row" gap="lg" justify="space-between" mt="md">
//               <Box
//                 style={{
//                   border: "1px solid #80808033",
//                   borderRadius: "5px",
//                   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                 }}
//                 bg="#F6F6F6"
//                 p="xs"
//                 w="30%"
//               >
//                 <Flex gap="xs">
//                   <Text c="#484848" fw="500" className="text">
//                     Ram:{" "}
//                   </Text>
//                   <Text c="#DC8585" fw="bold" className="text">
//                     {deviceHistory.ram}
//                   </Text>
//                 </Flex>
//               </Box>
//               <Box
//                 style={{
//                   border: "1px solid #80808033",
//                   borderRadius: "5px",
//                   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                 }}
//                 bg="#F6F6F6"
//                 p="xs"
//                 w="30%"
//               >
//                 <Flex gap="xs">
//                   <Text c="#484848" fw="500" className="text">
//                     Storage:{" "}
//                   </Text>
//                   <Text c="#DC8585" fw="bold" className="text">
//                     {deviceHistory.storage}
//                   </Text>
//                 </Flex>
//               </Box>
//               <Box
//                 style={{
//                   border: "1px solid #80808033",
//                   borderRadius: "5px",
//                   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                 }}
//                 bg="#F6F6F6"
//                 p="xs"
//                 w="30%"
//               >
//                 <Flex gap="xs">
//                   <Text c="#484848" fw="500" className="text">
//                     Mac Address:{" "}
//                   </Text>
//                   <Text c="#DC8585" fw="bold" className="text">
//                     {deviceHistory.macAddress}
//                   </Text>
//                 </Flex>
//               </Box>
//             </Flex>
//             <Flex direction="row" gap="lg" justify="space-between" mt="md">
//               <Box
//                 style={{
//                   border: "1px solid #80808033",
//                   borderRadius: "5px",
//                   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                 }}
//                 bg="#F6F6F6"
//                 p="xs"
//                 w="30%"
//               >
//                 <Flex gap="xs">
//                   <Text c="#484848" fw="500" className="text">
//                     Asset Owner:{" "}
//                   </Text>
//                   <Text c="#DC8585" fw="bold" className="text">
//                     {deviceHistory.ownerName}
//                   </Text>
//                 </Flex>
//               </Box>
//               <Box
//                 style={{
//                   border: "1px solid #80808033",
//                   borderRadius: "5px",
//                   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                 }}
//                 bg="#F6F6F6"
//                 p="xs"
//                 w="30%"
//               >
//                 <Flex gap="xs">
//                   <Text c="#484848" fw="500" className="text">
//                     Ownership Type:{" "}
//                   </Text>
//                   <Text c="#DC8585" fw="bold" className="text">
//                     {deviceHistory.ownershipType}
//                   </Text>
//                 </Flex>
//               </Box>
//               <Box
//                 style={{
//                   border: "1px solid #80808033",
//                   borderRadius: "5px",
//                   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                 }}
//                 bg="#F6F6F6"
//                 p="xs"
//                 w="30%"
//               >
//                 <Flex gap="xs">
//                   <Text c="#484848" fw="500" className="text">
//                     Cost:{" "}
//                   </Text>
//                   <Text c="#DC8585" fw="bold" className="text">
//                     {deviceHistory.cost}
//                   </Text>
//                 </Flex>
//               </Box>
//             </Flex>
//             <Flex
//               direction="row"
//               gap="xl"
//               justify="space-between"
//               mt="md"
//               align="center"
//             >
//               <Box
//                 style={{
//                   border: "1px solid #80808033",
//                   borderRadius: "5px",
//                   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                 }}
//                 bg="#F6F6F6"
//                 p="xs"
//                 w="30%"
//               >
//                 <Flex gap="xs">
//                   <Text c="#484848" fw="500" className="text">
//                     Added By:{" "}
//                   </Text>
//                   <Text c="#DC8585" fw="bold" className="text">
//                     {deviceHistory.added_by}
//                   </Text>
//                 </Flex>
//               </Box>
//               <Box w="30%">
//                 <Button bg="#182452" onClick={handleMarkAsDamaged}>
//                   Mark as Damaged
//                 </Button>
//               </Box>
//               <Flex direction="row" gap="lg" justify="center">
//                 <Button bg="#182452" onClick={openEditModal}>
//                   Edit
//                 </Button>
//                 <Button color="red" onClick={openDeleteModal}>
//                   Delete
//                 </Button>
//               </Flex>
//             </Flex>
//           </Flex>
//         </Paper>
//         <Text mt="md" c={"#393939BA"} fw={"bold"} size="20px" ml="md">
//           History
//         </Text>
//         <Paper m="md" style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
//           <HistoryTable deviceId={deviceId} />
//         </Paper>
//       </ScrollArea>
//       <Modal
//         opened={editModalOpened}
//         onClose={closeEditModal}
//         centered
//         transitionProps={{ transition: "rotate-left" }}
//         size="80%"
//       >
//         <EditDeviceModal
//           categoryName={deviceHistory.categoryName}
//           deviceName={deviceHistory.deviceName}
//           did={deviceHistory.did}
//           platformName={deviceHistory.platformName}
//           imei1={deviceHistory.imei1}
//           imei2={deviceHistory.imei2}
//           processor={deviceHistory.processor}
//           generation={deviceHistory.generation}
//           screenSize={deviceHistory.screenSize}
//           ram={deviceHistory.ram}
//           storage={deviceHistory.storage}
//           macAddress={deviceHistory.macAddress}
//           ownerName={deviceHistory.ownerName}
//           ownershipType={deviceHistory.ownershipType}
//           cost={deviceHistory.cost}
//           added_by={deviceHistory.added_by}
//           closeEditModal={closeEditModal}
//         />
//       </Modal>

//       <Modal
//         opened={deleteModalOpened}
//         onClose={closeDeleteModal}
//         centered
//         size="auto"
//         align="center"
//         transitionProps={{ transition: "rotate-left" }}
//         withCloseButton={false}
//       >
//         <Text>Are you sure you want to delete this device?</Text>
//         <Flex justify="flex-end" mt="md" gap="md" mr="sm">
//           <Button variant="outline" onClick={closeDeleteModal}>
//             Cancel
//           </Button>
//           <Button
//             color="red"
//             onClick={() => {
//               handleDeleteDevice();
//             }}
//           >
//             Delete
//           </Button>
//         </Flex>
//       </Modal>
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import {
  Text,
  Flex,
  Paper,
  Box,
  Button,
  Modal,
  Loader,
  ScrollArea,
} from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import HistoryTable from "./HistoryTable";
import { useDisclosure } from "@mantine/hooks";
import EditDeviceModal from "./EditDeviceModal";
import "./DeviceHistory.css";

export default function DeviceHistory() {
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const { deviceId } = useParams();
  const [deviceHistory, setDeviceHistory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editModalOpened, { open: openEditModal, close: closeEditModal }] =
    useDisclosure(false);
  const [
    deleteModalOpened,
    { open: openDeleteModal, close: closeDeleteModal },
  ] = useDisclosure(false);
  const [
    damageModalOpened,
    { open: openDamageModal, close: closeDamageModal },
  ] = useDisclosure(false);

  const fetchDeviceData = () => {
    setLoading(true);
    fetch(`${API_BASE_URL}/devices/get/device/${deviceId}`)
      .then((response) => response.json())
      .then((data) => {
        setDeviceHistory(data);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetchDeviceData();
  }, [deviceId]);

  const handleDeleteDevice = () => {
    fetch(`${API_BASE_URL}/devices/delete`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        did: deviceHistory.did, // Assuming deviceHistory contains the device ID
      }),
    })
      .then((response) => {
        if (response.ok) {
          navigate(-1);
        } else {
          throw new Error("Failed to delete device");
        }
      })
      .catch((error) => console.error("Error deleting device:", error));
  };

  const handleMarkAsDamaged = () => {
    fetch(`${API_BASE_URL}/devices/damage`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        did: deviceHistory.did,
        role: "Admin",
      }),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Device marked as damaged successfully.");
          // Optionally handle any additional UI updates or state changes here
        } else {
          throw new Error("Failed to mark device as damaged");
        }
      })
      .catch((error) =>
        console.error("Error marking device as damaged:", error)
      );
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" style={{ height: "100vh" }}>
        <Loader size={50} color="#182452" />
      </Flex>
    );
  }

  if (!deviceHistory) {
    return (
      <Text align="center" c="red">
        No history found for the selected device.
      </Text>
    );
  }

  return (
    <div>
      <Flex justify="space-between" align="center" mr="50px" ml="md">
        <Button bg="#182452" onClick={() => navigate(-1)}>
          Back
        </Button>
      </Flex>
      <ScrollArea style={{ height: "calc(100vh - 80px)" }}>
        <Paper m="md" style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
          <Flex
            // justify="space-between"
            p="30px"
            // align="center"
            direction="column"
          >
            <Flex direction="row" gap="lg" justify="space-between">
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Category:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceHistory.categoryName}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Device Name:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceHistory.deviceName}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Paltform:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceHistory.platformName}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Flex direction="row" gap="lg" justify="space-between" mt="md">
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Serial Number:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceHistory.did}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    IMEI 1:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceHistory.imei1}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    IMEI 2:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceHistory.imei12}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Flex direction="row" gap="lg" justify="space-between" mt="md">
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Processor:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceHistory.processor}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                w="30%"
                p="xs"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Genration:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceHistory.genration}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Screensize:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceHistory.screenSize}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Flex direction="row" gap="lg" justify="space-between" mt="md">
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Ram:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceHistory.ram}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Storage:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceHistory.storage}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Mac Address:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceHistory.macAddress}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Flex direction="row" gap="lg" justify="space-between" mt="md">
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Asset Owner:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceHistory.ownerName}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Ownership Type:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceHistory.ownershipType}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Cost:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceHistory.cost}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Flex
              direction="row"
              gap="xl"
              justify="space-between"
              mt="md"
              align="center"
            >
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Added By:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceHistory.added_by}
                  </Text>
                </Flex>
              </Box>
              <Box w="30%">
                <Button bg="#182452" onClick={openDamageModal}>
                  Mark as Damaged
                </Button>
              </Box>
              <Flex direction="row" gap="lg" justify="center">
                <Button bg="#182452" onClick={openEditModal}>
                  Edit
                </Button>
                <Button color="red" onClick={openDeleteModal}>
                  Delete
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Paper>
        <Text mt="md" c={"#393939BA"} fw={"bold"} size="20px" ml="md">
          History
        </Text>
        <Paper m="md" style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
          <HistoryTable deviceId={deviceId} />
        </Paper>
      </ScrollArea>
      <Modal
        opened={editModalOpened}
        onClose={() => {
          closeEditModal();
          fetchDeviceData();
        }}
        centered
        transitionProps={{ transition: "rotate-left" }}
        size="80%"
      >
        <EditDeviceModal
          categoryName={deviceHistory.categoryName}
          deviceName={deviceHistory.deviceName}
          did={deviceHistory.did}
          platformName={deviceHistory.platformName}
          imei1={deviceHistory.imei1}
          imei2={deviceHistory.imei2}
          processor={deviceHistory.processor}
          generation={deviceHistory.generation}
          screenSize={deviceHistory.screenSize}
          ram={deviceHistory.ram}
          storage={deviceHistory.storage}
          macAddress={deviceHistory.macAddress}
          ownerName={deviceHistory.ownerName}
          ownershipType={deviceHistory.ownershipType}
          cost={deviceHistory.cost}
          added_by={deviceHistory.added_by}
          closeEditModal={closeEditModal}
        />
      </Modal>

      <Modal
        opened={deleteModalOpened}
        onClose={closeDeleteModal}
        centered
        size="auto"
        align="center"
        transitionProps={{ transition: "rotate-left" }}
        withCloseButton={false}
      >
        <Text>Are you sure you want to Delete this device?</Text>
        <Flex justify="flex-end" mt="md" gap="md" mr="sm">
          <Button variant="outline" onClick={closeDeleteModal}>
            Cancel
          </Button>
          <Button
            color="red"
            onClick={() => {
              handleDeleteDevice();
            }}
          >
            Delete
          </Button>
        </Flex>
      </Modal>
      <Modal
        opened={damageModalOpened}
        onClose={closeDamageModal}
        centered
        size="auto"
        align="center"
        transitionProps={{ transition: "rotate-left" }}
        withCloseButton={false}
      >
        <Text>Are you sure you want to "Mark as Damage" this device?</Text>
        <Flex justify="flex-end" gap="md" mr="sm" mt="lg">
          <Button variant="outline" onClick={closeDamageModal}>
            Cancel
          </Button>
          <Button
            color="red"
            onClick={() => {
              handleMarkAsDamaged();
              navigate(-1);
            }}
          >
            Mark as Damaged
          </Button>
        </Flex>
      </Modal>
    </div>
  );
}
