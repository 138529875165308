import React, { useEffect, useState } from "react";
import { Box, Flex, Table, Text, Pagination } from "@mantine/core";

export default function HistoryTable({ deviceId }) {
  const [bookingHistory, setBookingHistory] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 15;
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    fetch(`${API_BASE_URL}/devices/get/device/${deviceId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch booking history");
        }
        return response.json();
      })
      .then((data) => {
        // Reverse the bookHistory array here
        const reversedHistory = data.bookHistory
          ? data.bookHistory.reverse()
          : [];
        setBookingHistory(reversedHistory);
      })
      .catch((error) => {
        console.error("Error fetching booking history:", error);
        // Optionally handle error state or display an error message
      });
  }, [deviceId]);

  const paginatedHistory = bookingHistory.slice(
    (activePage - 1) * itemsPerPage,
    activePage * itemsPerPage
  );

  const rows = paginatedHistory.map(
    (history, index) =>
      history.dateofbooking && (
        <Table.Tr key={index}>
          <Table.Td>{`${history.First_Name} ${history.Last_Name}`}</Table.Td>
          <Table.Td>
            {history.dateofbooking
              ? new Date(history.dateofbooking).toLocaleDateString()
              : "--------"}
          </Table.Td>
          <Table.Td>
            {history.dateOfReturn && !isNaN(new Date(history.dateOfReturn))
              ? new Date(history.dateOfReturn).toLocaleDateString()
              : history.dateOfApproval &&
                !isNaN(new Date(history.dateOfApproval))
              ? new Date(history.dateOfApproval).toLocaleDateString()
              : "--------"}
          </Table.Td>
          <Table.Td>{history.userid}</Table.Td>
          <Table.Td>{history.projectName}</Table.Td>
        </Table.Tr>
      )
  );

  return (
    <Box p="25px">
      {bookingHistory.length > 0 ? (
        <>
          <Table striped highlightOnHover withTableBorder withColumnBorders>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Employee Name</Table.Th>
                <Table.Th>Booked Date</Table.Th>
                <Table.Th>Return Date</Table.Th>
                <Table.Th>Employee Mail</Table.Th>
                <Table.Th>Project Name</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
          </Table>
          <Flex justify="center" mt="md">
            <Pagination
              page={activePage}
              onChange={setActivePage}
              total={Math.ceil(bookingHistory.length / itemsPerPage)}
              size="sm"
              color="#182452"
            />
          </Flex>
        </>
      ) : (
        <Flex align="center" justify="center" w="100%">
          <Text c={"#393939BA"} fw={"bold"} size="20px" mt="xl">
            No booking history available.
          </Text>
        </Flex>
      )}
    </Box>
  );
}
