import React, { useEffect, useState } from "react";
import {
  Text,
  Flex,
  Paper,
  Box,
  Button,
  Modal,
  Loader,
  ScrollArea,
} from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";

// import "./DeviceHistory.css";

export default function ViewDamagedDevice() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const { did } = useParams();
  const [deviceData, setDeviceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isRestoring, setIsRestoring] = useState(false);
  const [restoreModalOpened, { open, close }] = useDisclosure(false);
  useEffect(() => {
    fetch(`${API_BASE_URL}/devices/getdamaged`)
      .then((response) => response.json())
      .then((data) => {
        const device = data.find((item) => item.did === did);
        setDeviceData(device);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [did]);

  if (loading) {
    return (
      <Box align="center" mt="150px">
        <Loader color="#182452" />
      </Box>
    );
  }

  if (!deviceData) {
    return <Text>No device found with ID: {did}</Text>;
  }
  const handleRestore = () => {
    setIsRestoring(true);
    fetch(`${API_BASE_URL}/devices/repair`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ did: deviceData.did, role: "Admin" }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Device restored successfully:", data);
        // Handle successful restoration, e.g., navigate to another page or show a success message
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error restoring device:", error);
      })
      .finally(() => {
        setIsRestoring(false);
      });
  };

  
  return (
    <div>
      <Flex justify="space-between" align="center" mr="50px" ml="md">
        <Button bg="#182452" onClick={() => navigate(-1)}>
          Back
        </Button>
      </Flex>
      <ScrollArea style={{ height: "calc(100vh - 80px)" }}>
        <Paper m="md" style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
          <Flex direction="column" p="30px">
            <Flex direction="row" gap="lg" justify="space-between">
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Category:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceData.categoryName}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Device Name:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceData.deviceName}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Platform:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceData.platformName}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Flex direction="row" gap="lg" justify="space-between" mt="md">
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Serial Number:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceData.did}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    IMEI 1:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceData.imei1}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    IMEI 2:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceData.imei2}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Flex direction="row" gap="lg" justify="space-between" mt="md">
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Processor:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceData.processor}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                w="30%"
                p="xs"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Generation:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceData.generation}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Screen Size:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceData.screenSize}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Flex direction="row" gap="lg" justify="space-between" mt="md">
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    RAM:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceData.ram}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Storage Capacity:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceData.storage}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Mac Address:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceData.macAddress}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Flex direction="row" gap="lg" justify="space-between" mt="md">
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Asset Owner:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceData.ownerName}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Ownership Type:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceData.ownershipType}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Cost:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceData.cost}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Flex direction="row" gap="lg" justify="space-between" mt="md">
              {/* <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Added On:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceData.addedOn}
                  </Text>
                </Flex>
              </Box> */}
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Added By:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceData.added_by}
                  </Text>
                </Flex>
              </Box>
              {/* <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Damaged On:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceData.damagedOn}
                  </Text>
                </Flex>
              </Box> */}
            </Flex>
          </Flex>
        </Paper>
        <Flex m="md" justify="end" mt="xl">
          <Button bg="#182452" onClick={open} loading={isRestoring}>
            Restore
          </Button>
        </Flex>
      </ScrollArea>
      <Modal
        opened={restoreModalOpened}
        onClose={close}
        centered
        size="auto"
        align="center"
        transitionProps={{ transition: "rotate-left" }}
        withCloseButton={false}
      >
        <Text>Are you sure you want to Restore this device?</Text>
        <Flex justify="flex-end" mt="md" gap="xl" mr="sm">
          <Button variant="outline" onClick={close}>
            Cancel
          </Button>
          <Button
            color="#182452"
            onClick={() => {
              handleRestore();
            }}
          >
            Restore
          </Button>
        </Flex>
      </Modal>
    </div>
  );
}
