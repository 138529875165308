// import {
//   Box,
//   Button,
//   Divider,
//   Flex,
//   Paper,
//   Table,
//   Text,
//   Textarea,
//   ScrollArea,
// } from "@mantine/core";
// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";

// export default function ViewRequest() {
//   const navigate = useNavigate();
//   const { did } = useParams();
//   const [requestData, setRequestData] = useState(null);
//   const [reason, setReason] = useState("");

//   useEffect(() => {
//     // Fetch the request data
//     axios
//       .get(
//         "https://d13d04nxcwaid6.cloudfront.net/api/v1/admin/devices/rejected-history"
//       )
//       .then((response) => {
//         const { returnRejectedDevices } = response.data;
//         const request = returnRejectedDevices.find((item) => item.did === did);
//         if (request) {
//           setRequestData(request);
//           const rejectedEntry = request.bookHistory.find(
//             (entry) => entry.status === "return rejected"
//           );
//           if (rejectedEntry) {
//             setReason(rejectedEntry.rejectionReason);
//           }
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching data: ", error);
//       });
//   }, [did]);

//   if (!requestData) {
//     return <div>Loading...</div>;
//   }

//   const approveRequest = () => {
//     const apiUrl = `https://d13d04nxcwaid6.cloudfront.net/api/v1/admin/devices/approve-rejected-return`;

//     const requestBody = {
//       did: requestData.did,
//       role: "Admin",
//     };

//     axios
//       .post(apiUrl, requestBody)
//       .then((response) => {
//         console.log("Request processed:", response.data);
//         navigate(-1);
//       })
//       .catch((error) => {
//         console.error("Error processing request:", error);
//       });
//   };

//   const {
//     categoryName,
//     platformName,
//     deviceName,
//     did: deviceId,
//     imei1,
//     imei12,
//     processor,
//     genration,
//     screenSize,
//     ram,
//     storage,
//     macAddress,
//     assetOwner,
//     ownershipType,
//     cost,
//     added_by,
//     bookHistory,
//   } = requestData;

//   return (
// <div>
//   <Button bg="#182452" m="md" onClick={() => navigate(-1)}>
//     Back
//   </Button>
//   <Text mt="md" c={"#393939BA"} fw={"bold"} size="20px" ml="md">
//     View Request
//   </Text>
//   <ScrollArea>
//     <Paper m="md" style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
//       <Flex p="30px" direction="column">
//         <Flex direction="row" gap="lg" justify="space-between">
//           <Box
//             style={{
//               border: "1px solid #80808033",
//               borderRadius: "5px",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//             }}
//             bg="#F6F6F6"
//             p="xs"
//             w="30%"
//           >
//             <Flex gap="xs">
//               <Text c="#484848" fw="500">
//                 Category:{" "}
//               </Text>
//               <Text c="#DC8585" fw="bold">
//                 {categoryName}
//               </Text>
//             </Flex>
//           </Box>
//           <Box
//             style={{
//               border: "1px solid #80808033",
//               borderRadius: "5px",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//             }}
//             bg="#F6F6F6"
//             p="xs"
//             w="30%"
//           >
//             <Flex gap="xs">
//               <Text c="#484848" fw="500">
//                 Platform:{" "}
//               </Text>
//               <Text c="#DC8585" fw="bold">
//                 {platformName}
//               </Text>
//             </Flex>
//           </Box>
//           <Box
//             style={{
//               border: "1px solid #80808033",
//               borderRadius: "5px",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//             }}
//             bg="#F6F6F6"
//             p="xs"
//             w="30%"
//           >
//             <Flex gap="xs">
//               <Text c="#484848" fw="500">
//                 Device Name:{" "}
//               </Text>
//               <Text c="#DC8585" fw="bold">
//                 {deviceName}
//               </Text>
//             </Flex>
//           </Box>
//         </Flex>
//         <Flex direction="row" gap="lg" justify="space-between" mt="md">
//           <Box
//             style={{
//               border: "1px solid #80808033",
//               borderRadius: "5px",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//             }}
//             bg="#F6F6F6"
//             p="xs"
//             w="30%"
//           >
//             <Flex gap="xs">
//               <Text c="#484848" fw="500">
//                 Serial Number:{" "}
//               </Text>
//               <Text c="#DC8585" fw="bold">
//                 {deviceId}
//               </Text>
//             </Flex>
//           </Box>
//           <Box
//             style={{
//               border: "1px solid #80808033",
//               borderRadius: "5px",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//             }}
//             bg="#F6F6F6"
//             p="xs"
//             w="30%"
//           >
//             <Flex gap="xs">
//               <Text c="#484848" fw="500">
//                 IMEI 1:{" "}
//               </Text>
//               <Text c="#DC8585" fw="bold">
//                 {imei1}
//               </Text>
//             </Flex>
//           </Box>
//           <Box
//             style={{
//               border: "1px solid #80808033",
//               borderRadius: "5px",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//             }}
//             bg="#F6F6F6"
//             p="xs"
//             w="30%"
//           >
//             <Flex gap="xs">
//               <Text c="#484848" fw="500">
//                 IMEI 2:{" "}
//               </Text>
//               <Text c="#DC8585" fw="bold">
//                 {imei12}
//               </Text>
//             </Flex>
//           </Box>
//         </Flex>
//         <Flex direction="row" gap="lg" justify="space-between" mt="md">
//           <Box
//             style={{
//               border: "1px solid #80808033",
//               borderRadius: "5px",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//             }}
//             bg="#F6F6F6"
//             p="xs"
//             w="30%"
//           >
//             <Flex gap="xs">
//               <Text c="#484848" fw="500">
//                 Processor:{" "}
//               </Text>
//               <Text c="#DC8585" fw="bold">
//                 {processor}
//               </Text>
//             </Flex>
//           </Box>
//           <Box
//             style={{
//               border: "1px solid #80808033",
//               borderRadius: "5px",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//             }}
//             bg="#F6F6F6"
//             w="30%"
//             p="xs"
//           >
//             <Flex gap="xs">
//               <Text c="#484848" fw="500">
//                 Generation:{" "}
//               </Text>
//               <Text c="#DC8585" fw="bold">
//                 {genration ? genration : "-----"}
//               </Text>
//             </Flex>
//           </Box>
//           <Box
//             style={{
//               border: "1px solid #80808033",
//               borderRadius: "5px",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//             }}
//             bg="#F6F6F6"
//             p="xs"
//             w="30%"
//           >
//             <Flex gap="xs">
//               <Text c="#484848" fw="500">
//                 Screensize:{" "}
//               </Text>
//               <Text c="#DC8585" fw="bold">
//                 {screenSize}
//               </Text>
//             </Flex>
//           </Box>
//         </Flex>
//         <Flex direction="row" gap="lg" justify="space-between" mt="md">
//           <Box
//             style={{
//               border: "1px solid #80808033",
//               borderRadius: "5px",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//             }}
//             bg="#F6F6F6"
//             p="xs"
//             w="30%"
//           >
//             <Flex gap="xs">
//               <Text c="#484848" fw="500">
//                 RAM:{" "}
//               </Text>
//               <Text c="#DC8585" fw="bold">
//                 {ram}
//               </Text>
//             </Flex>
//           </Box>
//           <Box
//             style={{
//               border: "1px solid #80808033",
//               borderRadius: "5px",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//             }}
//             bg="#F6F6F6"
//             p="xs"
//             w="30%"
//           >
//             <Flex gap="xs">
//               <Text c="#484848" fw="500">
//                 Storage:{" "}
//               </Text>
//               <Text c="#DC8585" fw="bold">
//                 {storage}
//               </Text>
//             </Flex>
//           </Box>
//           <Box
//             style={{
//               border: "1px solid #80808033",
//               borderRadius: "5px",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//             }}
//             bg="#F6F6F6"
//             p="xs"
//             w="30%"
//           >
//             <Flex gap="xs">
//               <Text c="#484848" fw="500">
//                 Mac Address:{" "}
//               </Text>
//               <Text c="#DC8585" fw="bold">
//                 {macAddress ? macAddress : "-----"}
//               </Text>
//             </Flex>
//           </Box>
//         </Flex>
//         <Flex direction="row" gap="lg" justify="space-between" mt="md">
//           <Box
//             style={{
//               border: "1px solid #80808033",
//               borderRadius: "5px",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//             }}
//             bg="#F6F6F6"
//             p="xs"
//             w="30%"
//           >
//             <Flex gap="xs">
//               <Text c="#484848" fw="500">
//                 Asset Owner:{" "}
//               </Text>
//               <Text c="#DC8585" fw="bold">
//                 {assetOwner}
//               </Text>
//             </Flex>
//           </Box>
//           <Box
//             style={{
//               border: "1px solid #80808033",
//               borderRadius: "5px",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//             }}
//             bg="#F6F6F6"
//             p="xs"
//             w="30%"
//           >
//             <Flex gap="xs">
//               <Text c="#484848" fw="500">
//                 Ownership Type:{" "}
//               </Text>
//               <Text c="#DC8585" fw="bold">
//                 {ownershipType}
//               </Text>
//             </Flex>
//           </Box>
//           <Box
//             style={{
//               border: "1px solid #80808033",
//               borderRadius: "5px",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//             }}
//             bg="#F6F6F6"
//             p="xs"
//             w="30%"
//           >
//             <Flex gap="xs">
//               <Text c="#484848" fw="500">
//                 Cost:{" "}
//               </Text>
//               <Text c="#DC8585" fw="bold">
//                 {cost}
//               </Text>
//             </Flex>
//           </Box>
//         </Flex>
//         <Divider mt="md" />
//         <Flex direction="column" mt="md">
//           <Text c="#484848" fw="600">
//             Requestee Name:{" "}
//             <Text component="span" c="#DC8585" fw="bold">
//               {added_by}
//             </Text>
//           </Text>
//           <Text c="#484848" fw="600" mt="md" size="18px">
//             Reason for Rejection
//           </Text>
//           {/* <Textarea value={reason} disabled mt="md" minRows={3} /> */}
//           <Box
//             style={{
//               border: "1px solid #80808033",
//               borderRadius: "5px",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//             }}
//             bg="#F6F6F6"
//             p="md"
//             mt="md"
//           >
//             <Text c="#484848" fw="500">
//               {reason}
//             </Text>
//           </Box>

//           <Box align="end">
//             <Button bg="#182452" mt="md" onClick={approveRequest} w="10%">
//               Approve Request
//             </Button>
//           </Box>
//         </Flex>
//       </Flex>
//     </Paper>
//   </ScrollArea>
// </div>
//   );
// }

import {
  Box,
  Button,
  Divider,
  Flex,
  Paper,
  ScrollArea,
  Text,
} from "@mantine/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function ViewRequest() {
  const navigate = useNavigate();
  const { did } = useParams();
  const [requestData, setRequestData] = useState(null);
  const [requesteeName, setRequesteeName] = useState("");
  const [reason, setReason] = useState("");
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  useEffect(() => {
    // Fetch the request data
    axios
      .get(
        `${API_BASE_URL}/devices/rejected-history`
      )
      .then((response) => {
        const { returnRejectedDevices } = response.data;
        const request = returnRejectedDevices.find((item) => item.did === did);
        if (request) {
          setRequestData(request);
          const lastEntry = request.bookHistory[request.bookHistory.length - 1];
          if (lastEntry) {
            setRequesteeName(`${lastEntry.First_Name} ${lastEntry.Last_Name}`);
            setReason(lastEntry.rejectionReason);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [did]);

  if (!requestData) {
    return <div>Loading...</div>;
  }

  const approveRequest = () => {
    const apiUrl = `${API_BASE_URL}/devices/approve-rejected-return`;

    const requestBody = {
      did: requestData.did,
      role: "Admin",
    };

    axios
      .post(apiUrl, requestBody)
      .then((response) => {
        console.log("Request processed:", response.data);
        navigate(-1);
      })
      .catch((error) => {
        console.error("Error processing request:", error);
      });
  };

  const {
    categoryName,
    platformName,
    deviceName,
    did: deviceId,
    imei1,
    imei12,
    processor,
    genration,
    screenSize,
    ram,
    storage,
    macAddress,
    ownerName,
    ownershipType,
    cost,
    projectName,
  } = requestData;

  return (
    <div>
      <Button bg="#182452" m="md" onClick={() => navigate(-1)}>
        Back
      </Button>
      <ScrollArea h={"calc(100vh - 120px)"}>
        <Text mt="md" c={"#393939BA"} fw={"bold"} size="20px" ml="md">
          View Request
        </Text>
        <Paper m="md" style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
          <Flex p="30px" direction="column">
            <Flex direction="row" gap="lg" justify="space-between">
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Category:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {categoryName}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Platform:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {platformName}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Device Name:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceName}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Flex direction="row" gap="lg" justify="space-between" mt="md">
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Serial Number:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {deviceId}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    IMEI 1:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {imei1}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    IMEI 2:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {imei12}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Flex direction="row" gap="lg" justify="space-between" mt="md">
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Processor:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {processor}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                w="30%"
                p="xs"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Generation:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {genration ? genration : "-----"}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Screensize:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {screenSize}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Flex direction="row" gap="lg" justify="space-between" mt="md">
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    RAM:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {ram}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Storage:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {storage}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Mac Address:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {macAddress ? macAddress : "-----"}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Flex direction="row" gap="lg" justify="space-between" mt="md">
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Asset Owner:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {ownerName}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Ownership Type:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {ownershipType}
                  </Text>
                </Flex>
              </Box>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="xs"
                w="30%"
              >
                <Flex gap="xs">
                  <Text c="#484848" fw="500" className="text">
                    Cost:{" "}
                  </Text>
                  <Text c="#DC8585" fw="bold" className="text">
                    {cost}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Divider mt="md" />
            <Flex direction="column" mt="md">
              <Flex gap="50px">
                <Box>
                  <Text c="#484848" fw="600" className="text">
                    Requestee Name:{" "}
                    <Text
                      component="span"
                      c="#DC8585"
                      fw="bold"
                      className="text"
                    >
                      {requesteeName}
                    </Text>
                  </Text>
                </Box>
                <Box>
                  <Text c="#484848" fw="600" className="text">
                    Project:{" "}
                    <Text
                      component="span"
                      c="#DC8585"
                      fw="bold"
                      className="text"
                    >
                      {projectName}
                    </Text>
                  </Text>
                </Box>
              </Flex>
              <Text c="#484848" fw="600" mt="md" size="18px" className="text">
                Reason for Rejection
              </Text>
              <Box
                style={{
                  border: "1px solid #80808033",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                bg="#F6F6F6"
                p="md"
                mt="md"
              >
                <Text c="#484848" fw="500">
                  {reason}
                </Text>
              </Box>

              <Box align="end">
                <Button bg="#182452" mt="md" onClick={approveRequest} w="10%">
                  Approve Request
                </Button>
              </Box>
            </Flex>
          </Flex>
        </Paper>
      </ScrollArea>
    </div>
  );
}
