// import { Box, ScrollArea, Table, TextInput } from "@mantine/core";
// import { useEffect, useState } from "react";
// import axios from "axios";

// function ProjectTable() {
//   const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
//   const [search, setSearch] = useState("");
//   const [elements, setElements] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           `${API_BASE_URL}/devices/count-by-project`
//         );
//         setElements(response.data);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     const delayFetch = setTimeout(fetchData, 1000);

//     return () => clearTimeout(delayFetch);
//   }, []);

//   const filteredElements = elements.filter((element) =>
//     element.projectName.toLowerCase().includes(search.toLowerCase())
//   );

//   const rows = filteredElements.map((element) => (
//     <Table.Tr key={element.projectName}>
//       <Table.Td>{element.projectName}</Table.Td>
//       <Table.Td>{element.deviceCount}</Table.Td>
//     </Table.Tr>
//   ));

//   return (
//     <Box>
//       <TextInput
//         placeholder="Search projects"
//         value={search}
//         onChange={(event) => setSearch(event.currentTarget.value)}
//         mb="md"
//       />
//       <ScrollArea h={"calc(100vh - 650px)"}>
//         <Table striped highlightOnHover withTableBorder withColumnBorders>
//           <Table.Thead>
//             <Table.Tr>
//               <Table.Th>Project projectName</Table.Th>
//               <Table.Th>Assigned Devices</Table.Th>
//             </Table.Tr>
//           </Table.Thead>
//           <Table.Tbody>{rows}</Table.Tbody>
//         </Table>
//       </ScrollArea>
//     </Box>
//   );
// }

// export default ProjectTable;

// import { Box, ScrollArea, Table, TextInput } from "@mantine/core";
// import { useEffect, useState } from "react";
// import axios from "axios";

// function ProjectTable() {
//   const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
//   const [search, setSearch] = useState("");
//   const [elements, setElements] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           `${API_BASE_URL}/devices/count-by-project`
//         );
//         setElements(response.data);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     const delayFetch = setTimeout(fetchData, 1000);

//     return () => clearTimeout(delayFetch);
//   }, []);

//   const filteredElements = elements.filter(
//     (element) =>
//       element.projectName &&
//       element.projectName.toLowerCase().includes(search.toLowerCase())
//   );

//   const rows = filteredElements.map((element) => (
//     <Table.Tr key={element.projectName}>
//       {element.projectName !== "undefined" && (
//         <>
//           <Table.Td>{element.projectName}</Table.Td>
//           <Table.Td>{element.deviceCount}</Table.Td>
//         </>
//       )}
//     </Table.Tr>
//   ));

//   return (
//     <Box>
//       <TextInput
//         placeholder="Search projects"
//         value={search}
//         onChange={(event) => setSearch(event.currentTarget.value)}
//         mb="md"
//       />
//       <ScrollArea h={350}>
//         <Table striped highlightOnHover withTableBorder withColumnBorders>
//           <Table.Thead>
//             <Table.Tr>
//               <Table.Th>Project Name</Table.Th>
//               <Table.Th>Assigned Devices</Table.Th>
//             </Table.Tr>
//           </Table.Thead>
//           <Table.Tbody>{rows}</Table.Tbody>
//         </Table>
//       </ScrollArea>
//     </Box>
//   );
// }

// export default ProjectTable;

// import { Box, ScrollArea, Table, TextInput } from "@mantine/core";
// import { useEffect, useState } from "react";
// import axios from "axios";

// function ProjectTable() {
//   const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
//   const [search, setSearch] = useState("");
//   const [elements, setElements] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           `${API_BASE_URL}/devices/countbyprojectandcategory`
//         );
//         console.log("API response data:", response.data); // Debugging line
//         setElements(response.data);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     const delayFetch = setTimeout(fetchData, 1000);

//     return () => clearTimeout(delayFetch);
//   }, [API_BASE_URL]);

//   const filteredElements = elements.filter(
//     (element) =>
//       element.projectName &&
//       element.projectName.toLowerCase().includes(search.toLowerCase())
//   );

//   const rows = filteredElements.map((element) => (
//     <Table.Tr key={element.projectName}>
//       {element.projectName !== "undefined" && (
//         <>
//           <Table.Td>{element.projectName}</Table.Td>
//           <Table.Td>{element.deviceCount}</Table.Td>
//         </>
//       )}
//     </Table.Tr>
//   ));

//   return (
//     <Box>
//       <TextInput
//         placeholder="Search projects"
//         value={search}
//         onChange={(event) => setSearch(event.currentTarget.value)}
//         mb="md"
//       />
//       <ScrollArea h={350}>
//         <Table striped highlightOnHover withTableBorder withColumnBorders>
//           <Table.Thead>
//             <Table.Tr>
//               <Table.Th>Project Name</Table.Th>
//               <Table.Th>Assigned Devices</Table.Th>
//             </Table.Tr>
//           </Table.Thead>
//           <Table.Tbody>{rows}</Table.Tbody>
//         </Table>
//       </ScrollArea>
//     </Box>
//   );
// }

// export default ProjectTable;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Paper, Text, ScrollArea, Flex, Box } from "@mantine/core";
import ReactApexChart from "react-apexcharts";

const ProjectTable = ({ series, labels }) => {
  const options = {
    chart: {
      width: 380,
      type: "pie",
    },
    labels: labels,
    legend: {
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="pie"
          width={200}
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ProjectTable;
