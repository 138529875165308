import { useState, useEffect } from "react";
import { Button, Flex, Input, Modal, Paper, Text } from "@mantine/core";
import { IconEdit, IconPlus, IconTrash } from "@tabler/icons-react";
import axios from "axios";
import { useDisclosure } from "@mantine/hooks";

export default function AssetOwner() {
  const [assetOwners, setAssetOwners] = useState([]);
  const [opened, { open, close }] = useDisclosure(false);
  const [newOwnerName, setNewOwnerName] = useState("");
  const [currentOwnerName, setCurrentOwnerName] = useState("");
  const [editOwnerName, setEditOwnerName] = useState("");
  const [deleteOwnerName, setDeleteOwnerName] = useState("");
  const [
    deleteOwnerModalOpened,
    { open: openDeleteOwnerModal, close: closeDeleteOwnerModal },
  ] = useDisclosure(false);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [
    editOwnerModalOpened,
    { open: openEditOwnerModal, close: closeEditOwnerModal },
  ] = useDisclosure(false);

  const fetchAssetOwners = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/assetowner/all`);
      setAssetOwners(response.data);
    } catch (error) {
      console.error("Error fetching asset owners:", error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchAssetOwners();
    }, 1000); 

    return () => clearTimeout(timer); 
  }, []);
  const handleAddOwner = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/assetowner/add`, {
        ownerName: newOwnerName,
      });
      console.log("Owner added successfully:", response.data);
      fetchAssetOwners();
      setNewOwnerName("")
      close();
    } catch (error) {
      console.error("Failed to add owner:", error);
    }
  };

  const handleEditOwnerModal = (owner) => {
    setCurrentOwnerName(owner.ownerName);
    setEditOwnerName(owner.ownerName);
    openEditOwnerModal();
  };

  const handleEditOwner = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/assetowner/update`, {
        ownerName: currentOwnerName,
        newOwnerName: editOwnerName,
      });
      console.log("Owner updated successfully:", response.data);
      fetchAssetOwners();
      closeEditOwnerModal();
    } catch (error) {
      console.error("Failed to update owner:", error);
    }
  };

  const handleDeleteOwnerModal = (owner) => {
    setDeleteOwnerName(owner.ownerName);
    openDeleteOwnerModal();
  };

  const handleDeleteOwner = async () => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/assetowner/delete`, {
        data: { ownerName: deleteOwnerName },
      });
      console.log("Owner deleted successfully:", response.data);
      fetchAssetOwners();
      closeDeleteOwnerModal();
    } catch (error) {
      console.error("Failed to delete owner:", error);
    }
  };

  return (
    <div>
      <Flex justify="space-between" p="lg" align="center">
        <Text c="#393939BA" fw="bold" size="18px">
          Asset Owners
        </Text>
        <IconPlus
          style={{
            cursor: "pointer",
            width: 30,
            height: 30,
          }}
          color="#393939BA"
          onClick={open}
        />
      </Flex>
      <Paper bg="#FFFFFF" p="lg">
        {assetOwners.map((owner) => (
          <Flex key={owner._id} align="center" mb="sm" justify="space-between">
            <Text c="#393939BA" fw="bold">
              {owner.ownerName}
            </Text>
            <Flex gap="lg">
              <IconEdit
                color="#393939BA"
                style={{ cursor: "pointer", marginLeft: "auto" }}
                onClick={() => handleEditOwnerModal(owner)}
              />
              <IconTrash
                color="#393939BA"
                style={{ cursor: "pointer" }}
                onClick={() => handleDeleteOwnerModal(owner)}
              />
            </Flex>
          </Flex>
        ))}
      </Paper>
      <Modal opened={opened} onClose={close} centered>
        <Text mb="sm" c="#393939BA" fw="bold">
          Add New Owner
        </Text>
        <Input
          value={newOwnerName}
          onChange={(e) => setNewOwnerName(e.target.value)}
        />
        <Flex w="100%" justify="flex-end" mt="lg">
          <Button bg="#182452" onClick={handleAddOwner}>
            Add
          </Button>
        </Flex>
      </Modal>
      <Modal
        opened={editOwnerModalOpened}
        onClose={closeEditOwnerModal}
        centered
        withCloseButton
      >
        <Text mb="sm" c="#393939BA" fw="bold">
          Edit Owner Name
        </Text>
        <Input
          value={editOwnerName}
          onChange={(e) => setEditOwnerName(e.target.value)}
        />
        <Flex w="100%" justify="flex-end" mt="lg">
          <Button bg="#182452" onClick={handleEditOwner}>
            Edit
          </Button>
        </Flex>
      </Modal>
      <Modal
        opened={deleteOwnerModalOpened}
        onClose={closeDeleteOwnerModal}
        centered
        withCloseButton
      >
        <Text mb="sm" c="#393939BA" fw="bold">
          Are you sure you want to delete the owner "{deleteOwnerName}"?
        </Text>
        <Flex w="100%" justify="flex-end" mt="lg" gap="lg">
          <Button ml="sm" bg="#182452" onClick={closeDeleteOwnerModal}>
            Cancel
          </Button>
          <Button bg="red" onClick={handleDeleteOwner}>
            Delete
          </Button>
        </Flex>
      </Modal>
    </div>
  );
}
