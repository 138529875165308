import React, { useState, useEffect } from "react";
import axios from "axios";
import { Text, Flex, Paper, Box, ScrollArea, Button } from "@mantine/core";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";

export default function DamagedDeviceList() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const [damagedDevices, setDamagedDevices] = useState([]);

  useEffect(() => {
    const fetchDamagedDevices = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/devices/getdamaged`);
        if (Array.isArray(response.data)) {
          setDamagedDevices(response.data);
        } else {
          setDamagedDevices([]);
        }
      } catch (error) {
        console.error("Error fetching damaged devices:", error);
        setDamagedDevices([]); // Ensure it's an empty array in case of error
      }
    };
    fetchDamagedDevices();
  }, [API_BASE_URL]);

  return (
    <div>
      <Flex justify="space-between" align="center" mr="50px" ml="md">
        <Text c={"#393939BA"} fw={"bold"} size="20px" mb="md">
          Damaged Device List
        </Text>
      </Flex>
      <Button bg="#182452" onClick={() => navigate(-1)} m="md">
        Back
      </Button>
      <Paper
        bg="#FFFFFF"
        mt="lg"
        mr="sm"
        ml="sm"
        p="lg"
        style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
      >
        <Box
          p="10px"
          bg="#182452"
          style={{
            border: "1px solid #e0e0e0",
            borderRadius: "4px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Flex>
            <Text w={"25%"} fw={"bold"} c="#FFFFFF">
              Category
            </Text>
            <Text w={"25%"} fw={"bold"} c="#FFFFFF">
              Platform
            </Text>
            <Text w={"25%"} fw={"bold"} c="#FFFFFF">
              Device Name
            </Text>
            <Text w={"25%"} fw={"bold"} c="#FFFFFF">
              Device ID
            </Text>
          </Flex>
        </Box>
        <ScrollArea
          style={{ height: "calc(100vh - 270px)" }}
          type="never"
          mt="md"
        >
          {damagedDevices.length === 0 ? (
            <Text c="#393939BA" fw="600" p="md" ta="center">
              No damaged devices found
            </Text>
          ) : (
            damagedDevices.map((device) => (
              <Box
                key={device._id}
                style={{
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                  cursor: "pointer",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                m="10px"
                p="10px"
                className="DeviceList"
                onClick={() =>
                  navigate(
                    `/Dashboard/DamagedDeviceList/ViewDamagedDevice/${device.did}`
                  )
                }
              >
                <Flex justify="space-between" align="center">
                  <Text w={"25%"} c="#393939BA" fw="600">
                    {device.categoryName}
                  </Text>
                  <Text w={"25%"} c="#393939BA" fw="600">
                    {device.platformName}
                  </Text>
                  <Text w={"25%"} c="#393939BA" fw="600">
                    {device.deviceName}
                  </Text>
                  <Text w={"25%"} c="#393939BA" fw="600">
                    {device.did || "N/A"}
                  </Text>
                </Flex>
              </Box>
            ))
          )}
        </ScrollArea>
      </Paper>
    </div>
  );
}
