// import React, { useState, useEffect, useRef } from "react";
// import { Button, Flex, Input, Select, Text, Box } from "@mantine/core";
// import { IconChevronDown } from "@tabler/icons-react";
// import QrGeneratorEdit from "./QrGeneratorEdit";
// import axios from "axios";

// const EditDeviceModal = ({
//   categoryName,
//   deviceName,
//   did,
//   platformName,
//   imei1,
//   imei2,
//   processor,
//   generation,
//   screenSize,
//   ram,
//   storage,
//   macAddress,
//   assetOwner,
//   ownershipType,
//   cost,
// }) => {
//   const [category, setCategory] = useState(categoryName || "");
//   const [device, setDevice] = useState(deviceName || "");
//   const [platform, setPlatform] = useState(platformName || "");
//   const [deviceId, setDeviceId] = useState(did || "");
//   const [categories, setCategories] = useState([]);
//   const [platforms, setPlatforms] = useState([]);
//   const [devices, setDevices] = useState([]);
//   const [imei1State, setImei1State] = useState(imei1 || "");
//   const [imei2State, setImei2State] = useState(imei2 || "");
//   const [processorState, setProcessorState] = useState(processor || "");
//   const [generationState, setGenerationState] = useState(generation || "");
//   const [screenSizeState, setScreenSizeState] = useState(screenSize || "");
//   const [ramState, setRamState] = useState(ram || "");
//   const [storageState, setStorageState] = useState(storage || "");
//   const [macAddressState, setMacAddressState] = useState(macAddress || "");
//   const [ownerNameState, setOwnerNameState] = useState(assetOwner || "");
//   const [ownershipTypeState, setOwnershipTypeState] = useState(
//     ownershipType || ""
//   );
//   const [costState, setCostState] = useState(cost || "");
//   const [serialNum, setSerialNum] = useState("");
//   const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
//   const [assetOwners, setAssetOwners] = useState([]);
//   const qrRef = useRef();
//   const fetchCategories = async () => {
//     try {
//       const response = await fetch(`${API_BASE_URL}/category/get`);
//       const data = await response.json();
//       setCategories(data);
//       // Set default selected category to the props value or the first one in the response
//       const defaultCategory =
//         categoryName || (data.length > 0 ? data[0].categoryName : "");
//       setCategory(defaultCategory);
//       fetchPlatforms(defaultCategory); // Fetch platforms for the default category
//     } catch (error) {
//       console.error("Error fetching categories:", error);
//     }
//   };
//   const handlePrint = () => {
//     const printContent = qrRef.current.innerHTML;
//     const originalContent = document.body.innerHTML;

//     document.body.innerHTML = printContent;
//     window.print();
//     document.body.innerHTML = originalContent;
//     window.location.reload();
//   };

//   const fetchPlatforms = async (categoryName) => {
//     try {
//       const response = await fetch(`${API_BASE_URL}/platform/${categoryName}`);
//       const data = await response.json();
//       setPlatforms(data); // Set platforms to the fetched data array
//       // Set default selected platform to the props value or the first one in the response
//       const defaultPlatform =
//         platformName || (data.length > 0 ? data[0].platformName : "");
//       setPlatform(defaultPlatform);
//       fetchDevices(categoryName, defaultPlatform); // Fetch devices for the default platform
//     } catch (error) {
//       console.error("Error fetching platforms:", error);
//     }
//   };

//   const fetchDevices = async (categoryName, platformName) => {
//     try {
//       const response = await axios.get(
//         `${API_BASE_URL}/devicename/get/cat_pat?categoryName=${categoryName}&platformName=${platformName}`
//       );
//       if (response.data.error === "Device not found") {
//         setDevices([]);
//       } else {
//         setDevices(response.data);
//       }
//     } catch (error) {
//       console.error("Error fetching devices:", error);
//     }
//   };
//   const fetchAssetOwners = async () => {
//     try {
//       const response = await axios.get(`${API_BASE_URL}/assetowner/all`);
//       setAssetOwners(response.data);
//     } catch (error) {
//       console.error("Error fetching asset owners:", error);
//     }
//   };
//   useEffect(() => {
//     const timer = setTimeout(() => {
//       fetchAssetOwners();
//     }, 800); // 2000 milliseconds delay (2 seconds)

//     return () => clearTimeout(timer);
//   }, []);

//   const handleSaveChanges = async () => {
//     try {
//       const response = await axios.put(`${API_BASE_URL}/devices/edit`, {
//         category,
//         platform,
//         deviceName: device,
//         did: deviceId,
//         imei1: imei1State,
//         imei12: imei2State,
//         processor: processorState,
//         genration: generationState,
//         screenSize: screenSizeState,
//         ram: ramState,
//         storage: storageState,
//         macAddress: macAddressState,
//         assetOwner: ownerNameState,
//         ownershipType: ownershipTypeState,
//         cost: costState,
//       });
//       console.log("Device updated successfully:", response.data);
//       // Optionally, you can handle success feedback or UI updates here
//     } catch (error) {
//       console.error("Error updating device:", error);
//       // Handle error feedback or UI updates here
//     }
//   };
//   useEffect(() => {
//     fetchCategories();
//   }, []);

//   return (
//     <div>
//       <Text w="100%" align="center" fw="bold" c="#3E3E3E" size="18px">
//         Edit Device
//       </Text>
//       <Flex direction="column">
//         <Flex w="100%" justify="space-around">
//           <Select
//             label="Select Category"
//             data={categories.map((cat) => cat.categoryName)}
//             value={category}
//             rightSection={<IconChevronDown />}
//             labelProps={{ c: "#6D6D6D", fw: "bold" }}
//             onChange={(value) => {
//               setCategory(value);
//               setPlatforms([]);
//               setDevices([]);
//               fetchPlatforms(value);
//             }}
//             w={"30%"}
//           />
//           <Select
//             label="Platform"
//             data={platforms.map((plat) => plat.platformName)}
//             value={platform}
//             rightSection={<IconChevronDown />}
//             labelProps={{ c: "#6D6D6D", fw: "bold" }}
//             onChange={(value) => {
//               setPlatform(value);
//               setDevices([]);
//               fetchDevices(category, value);
//             }}
//             w={"30%"}
//           />
//           <Select
//             label="Device Name"
//             data={devices.map((dev) => dev.deviceName)}
//             value={device}
//             rightSection={<IconChevronDown />}
//             labelProps={{ c: "#6D6D6D", fw: "bold" }}
//             onChange={(value) => setDevice(value)}
//             w={"30%"}
//           />
//         </Flex>
//         <Flex align="center" justify="space-around" mt="lg">
//           <Box w="30%">
//             <Flex gap="3px">
//               <Text size="14px" fw="bold" c="#6D6D6D">
//                 Serial No <span style={{ color: "red" }}>*</span>
//               </Text>
//             </Flex>
//             <Input
//               mt="xs"
//               value={deviceId}
//               // onChange={(event) => setDeviceId(event.target.value)}
//             />
//             {/* {fieldErrors.serialNum && (
//               <Text c="red" size="12px">
//                 {fieldErrors.serialNum}
//               </Text>
//             )} */}
//           </Box>
//           <Box w="30%">
//             <Text size="14px" fw="bold" c="#6D6D6D">
//               IMEI 1
//             </Text>
//             <Input
//               mt="xs"
//               value={imei1State}
//               onChange={(event) => setImei1State(event.target.value)}
//             />
//           </Box>
//           <Box w="30%">
//             <Text size="14px" fw="bold" c="#6D6D6D">
//               IMEI 2
//             </Text>
//             <Input
//               mt="xs"
//               value={imei2State}
//               onChange={(event) => setImei2State(event.target.value)}
//             />
//           </Box>
//         </Flex>
//         <Flex align="center" justify="space-around" mt="lg">
//           <Box w="30%">
//             <Flex gap="3px">
//               <Text size="14px" fw="bold" c="#6D6D6D">
//                 Processor Name
//               </Text>
//             </Flex>
//             <Input
//               mt="xs"
//               value={processorState}
//               onChange={(event) => setProcessorState(event.target.value)}
//             />
//           </Box>
//           <Box w="30%">
//             <Flex gap="3px">
//               <Text size="14px" fw="bold" c="#6D6D6D">
//                 Generation
//               </Text>
//             </Flex>
//             <Input
//               mt="xs"
//               value={generationState}
//               onChange={(event) => setGenerationState(event.target.value)}
//             />
//           </Box>
//           <Box w="30%">
//             <Flex gap="3px">
//               <Text size="14px" fw="bold" c="#6D6D6D">
//                 Screen Size <span style={{ color: "red" }}>*</span>
//               </Text>
//             </Flex>
//             <Input
//               mt="xs"
//               value={screenSizeState}
//               onChange={(event) => setScreenSizeState(event.target.value)}
//             />
//             {/* {fieldErrors.screenSize && (
//               <Text c="red" size="12px">
//                 {fieldErrors.screenSize}
//               </Text>
//             )} */}
//           </Box>
//         </Flex>
//         <Flex align="center" justify="space-around" mt="lg">
//           <Box w="30%">
//             <Flex gap="3px">
//               <Text size="14px" fw="bold" c="#6D6D6D">
//                 RAM <span style={{ color: "red" }}>*</span>
//               </Text>
//             </Flex>
//             <Input
//               mt="xs"
//               value={ramState}
//               onChange={(event) => setRamState(event.target.value)}
//             />
//             {/* {fieldErrors.ram && (
//               <Text c="red" size="12px">
//                 {fieldErrors.ram}
//               </Text>
//             )} */}
//           </Box>
//           <Box w="30%">
//             <Flex gap="3px">
//               <Text size="14px" fw="bold" c="#6D6D6D">
//                 Storage Capacity <span style={{ color: "red" }}>*</span>
//               </Text>
//             </Flex>
//             <Input
//               mt="xs"
//               value={storageState}
//               onChange={(event) => setStorageState(event.target.value)}
//             />
//             {/* {fieldErrors.storage && (
//               <Text c="red" size="12px">
//                 {fieldErrors.storage}
//               </Text>
//             )} */}
//           </Box>
//           <Box w="30%">
//             <Flex gap="3px">
//               <Text size="14px" fw="bold" c="#6D6D6D">
//                 Mac Address <span style={{ color: "red" }}>*</span>
//               </Text>
//             </Flex>
//             <Input
//               mt="xs"
//               value={macAddressState}
//               onChange={(event) => setMacAddressState(event.target.value)}
//             />
//             {/* {fieldErrors.macAddress && (
//               <Text c="red" size="12px">
//                 {fieldErrors.macAddress}
//               </Text>
//             )} */}
//           </Box>
//         </Flex>
//         <Flex align="center" justify="space-around" mt="lg">
//           <Box w="30%">
//             <Flex gap="3px">
//               <Text size="14px" fw="bold" c="#6D6D6D">
//                 Asset Owner <span style={{ color: "red" }}>*</span>
//               </Text>
//             </Flex>
//             <Select
//               data={assetOwners.map((owner) => ({
//                 value: owner._id,
//                 label: owner.ownerName,
//               }))}
//               value={ownerNameState}
//               rightSection={<IconChevronDown />}
//               onChange={(value) => setOwnerNameState(value)}
//               mt="xs"
//             />
//           </Box>
//           <Box w="30%">
//             <Flex gap="3px">
//               <Text size="14px" fw="bold" c="#6D6D6D">
//                 Ownership Type <span style={{ color: "red" }}>*</span>
//               </Text>
//             </Flex>
//             <Input
//               mt="xs"
//               value={ownershipTypeState}
//               onChange={(event) => setOwnershipTypeState(event.target.value)}
//             />
//             {/* {fieldErrors.ownershipType && (
//               <Text c="red" size="12px">
//                 {fieldErrors.ownershipType}
//               </Text>
//             )} */}
//           </Box>
//           <Box w="30%">
//             <Flex gap="3px">
//               <Text size="14px" fw="bold" c="#6D6D6D">
//                 Cost <span style={{ color: "red" }}>*</span>
//               </Text>
//             </Flex>
//             <Input
//               mt="xs"
//               value={costState}
//               onChange={(event) => setCostState(event.target.value)}
//             />
//             {/* {fieldErrors.cost && (
//               <Text c="red" size="12px">
//                 {fieldErrors.cost}
//               </Text>
//             )} */}
//           </Box>
//         </Flex>
//         <Box align="center" mt="md">
//           <Box>
//             <Box align="center">
//               <Button mt="md" bg="#182452" onClick={handleSaveChanges}>
//                 Save Changes
//               </Button>
//             </Box>
//           </Box>
//           <Box
//             ref={qrRef}
//             style={{
//               boxShadow: "0 4px 18px rgba(0, 0, 0, 0.1)",
//               borderRadius: "5px",
//               border: "1px solid #8080805E",
//               padding: 10,
//               // align: "center",
//             }}
//             w="20%"
//             mt="lg"
//           >
//             <QrGeneratorEdit value={deviceId} />
//           </Box>
//         </Box>

//         <Box align="center">
//           <Button mt="md" bg="#182452" onClick={handlePrint}>
//             Print
//           </Button>
//         </Box>
//       </Flex>
//     </div>
//   );
// };

// export default EditDeviceModal;

import React, { useState, useEffect, useRef } from "react";
import { Button, Flex, Input, Select, Text, Box } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import QrGeneratorEdit from "./QrGeneratorEdit";
import axios from "axios";

const EditDeviceModal = ({
  categoryName,
  deviceName,
  did,
  platformName,
  imei1,
  imei2,
  processor,
  generation,
  screenSize,
  ram,
  storage,
  macAddress,
  ownerName,
  ownershipType,
  cost,
}) => {
  const [category, setCategory] = useState(categoryName || "");
  const [device, setDevice] = useState(deviceName || "");
  const [platform, setPlatform] = useState(platformName || "");
  const [deviceId, setDeviceId] = useState(did || "");
  const [categories, setCategories] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [devices, setDevices] = useState([]);
  const [imei1State, setImei1State] = useState(imei1 || "");
  const [imei2State, setImei2State] = useState(imei2 || "");
  const [processorState, setProcessorState] = useState(processor || "");
  const [generationState, setGenerationState] = useState(generation || "");
  const [screenSizeState, setScreenSizeState] = useState(screenSize || "");
  const [ramState, setRamState] = useState(ram || "");
  const [storageState, setStorageState] = useState(storage || "");
  const [macAddressState, setMacAddressState] = useState(macAddress || "");
  const [ownerNameState, setOwnerNameState] = useState(ownerName || "");
  const [saveButton, setSaveButton] = useState(true);
  const [ownershipTypeState, setOwnershipTypeState] = useState(
    ownershipType || ""
  );
  const [fieldErrors, setFieldErrors] = useState({});
  const [error, setError] = useState("");
  const [costState, setCostState] = useState(cost || "");
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [assetOwners, setAssetOwners] = useState([]);
  const qrRef = useRef();
  const fetchCategories = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/category/get`);
      const data = await response.json();
      setCategories(data);
      const defaultCategory =
        categoryName || (data.length > 0 ? data[0].categoryName : "");
      setCategory(defaultCategory);
      fetchPlatforms(defaultCategory);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handlePrint = () => {
    const printContent = qrRef.current.innerHTML;
    const originalContent = document.body.innerHTML;

    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContent;
    window.location.reload();
  };

  const fetchPlatforms = async (categoryName) => {
    try {
      const response = await fetch(`${API_BASE_URL}/platform/${categoryName}`);
      const data = await response.json();
      setPlatforms(data);
      const defaultPlatform =
        platformName || (data.length > 0 ? data[0].platformName : "");
      setPlatform(defaultPlatform);
      fetchDevices(categoryName, defaultPlatform);
    } catch (error) {
      console.error("Error fetching platforms:", error);
    }
  };

  const fetchDevices = async (categoryName, platformName) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/devicename/get/cat_pat?categoryName=${categoryName}&platformName=${platformName}`
      );
      if (response.data.error === "Device not found") {
        setDevices([]);
      } else {
        setDevices(response.data);
      }
    } catch (error) {
      console.error("Error fetching devices:", error);
    }
  };

  const fetchAssetOwners = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/assetowner/all`);
      setAssetOwners(response.data);
    } catch (error) {
      console.error("Error fetching asset owners:", error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchAssetOwners();
    }, 800);

    return () => clearTimeout(timer);
  }, []);

  const handleSaveChanges = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/devices/edit`, {
        category,
        platform,
        deviceName: device,
        did: deviceId,
        imei1: imei1State,
        imei2: imei2State,
        processor: processorState,
        generation: generationState,
        screenSize: screenSizeState,
        ram: ramState,
        storage: storageState,
        macAddress: macAddressState,
        ownerName: ownerNameState,
        ownershipType: ownershipTypeState,
        cost: costState,
      });
      console.log("Device updated successfully:", response.data);
      setSaveButton(false);
    } catch (error) {
      console.error("Error updating device:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleSubmit = () => {
    let errors = {};

    if (!deviceId) {
      errors.serialNum = "Serial Number is required.";
    }
    if (!screenSizeState) {
      errors.screenSize = "Screen Size is required.";
    }
    if (!ramState) {
      errors.ram = "RAM is required.";
    }
    if (!storageState) {
      errors.storage = "Storage Capacity is required.";
    }
    if (!macAddressState) {
      errors.macAddress = "Mac Address is required.";
    }
    if (!ownerNameState) {
      errors.assetOwner = "Asset Owner is required.";
    }
    if (!ownershipTypeState) {
      errors.ownershipType = "Ownership Type is required.";
    }
    if (!costState) {
      errors.cost = "Cost is required.";
    }
    if (!ownerNameState) {
      errors.assetOwner = "Asset Owner is required.";
    }
    setFieldErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    if (error) {
      return;
    }

    handleSaveChanges();
  };

  return (
    <div>
      <Text w="100%" align="center" fw="bold" c="#3E3E3E" size="18px">
        Edit Device
      </Text>
      <Flex direction="column">
        <Flex w="100%" justify="space-around">
          <Select
            label="Select Category"
            data={categories.map((cat) => cat.categoryName)}
            value={category}
            rightSection={<IconChevronDown />}
            labelProps={{ c: "#6D6D6D", fw: "bold" }}
            onChange={(value) => {
              setCategory(value);
              setPlatforms([]);
              setDevices([]);
              fetchPlatforms(value);
            }}
            w={"30%"}
          />
          <Select
            label="Platform"
            data={platforms.map((plat) => plat.platformName)}
            value={platform}
            rightSection={<IconChevronDown />}
            labelProps={{ c: "#6D6D6D", fw: "bold" }}
            onChange={(value) => {
              setPlatform(value);
              setDevices([]);
              fetchDevices(category, value);
            }}
            w={"30%"}
          />
          <Select
            label="Device Name"
            data={devices.map((dev) => dev.deviceName)}
            value={device}
            rightSection={<IconChevronDown />}
            labelProps={{ c: "#6D6D6D", fw: "bold" }}
            onChange={(value) => setDevice(value)}
            w={"30%"}
          />
        </Flex>
        <Flex align="center" justify="space-around" mt="lg">
          <Box w="30%">
            <Flex gap="3px">
              <Text size="14px" fw="bold" c="#6D6D6D">
                Serial No <span style={{ color: "red" }}>*</span>
              </Text>
            </Flex>
            <Input
              mt="xs"
              value={deviceId}
              disabled
              // onChange={(event) => setDeviceId(event.target.value)}
            />
            {fieldErrors.serialNum && (
              <Text c="red" size="12px">
                {fieldErrors.serialNum}
              </Text>
            )}
          </Box>
          <Box w="30%">
            <Text size="14px" fw="bold" c="#6D6D6D">
              IMEI 1
            </Text>
            <Input
              mt="xs"
              value={imei1State}
              onChange={(event) => setImei1State(event.target.value)}
            />
          </Box>
          <Box w="30%">
            <Text size="14px" fw="bold" c="#6D6D6D">
              IMEI 2
            </Text>
            <Input
              mt="xs"
              value={imei2State}
              onChange={(event) => setImei2State(event.target.value)}
            />
          </Box>
        </Flex>
        <Flex align="center" justify="space-around" mt="lg">
          <Box w="30%">
            <Flex gap="3px">
              <Text size="14px" fw="bold" c="#6D6D6D">
                Processor Name
              </Text>
            </Flex>
            <Input
              mt="xs"
              value={processorState}
              onChange={(event) => setProcessorState(event.target.value)}
            />
          </Box>
          <Box w="30%">
            <Flex gap="3px">
              <Text size="14px" fw="bold" c="#6D6D6D">
                Generation
              </Text>
            </Flex>
            <Input
              mt="xs"
              value={generationState}
              onChange={(event) => setGenerationState(event.target.value)}
            />
          </Box>
          <Box w="30%">
            <Flex gap="3px">
              <Text size="14px" fw="bold" c="#6D6D6D">
                Screen Size <span style={{ color: "red" }}>*</span>
              </Text>
            </Flex>
            <Input
              mt="xs"
              value={screenSizeState}
              onChange={(event) => setScreenSizeState(event.target.value)}
            />
            {fieldErrors.screenSize && (
              <Text c="red" size="12px">
                {fieldErrors.screenSize}
              </Text>
            )}
          </Box>
        </Flex>
        <Flex align="center" justify="space-around" mt="lg">
          <Box w="30%">
            <Flex gap="3px">
              <Text size="14px" fw="bold" c="#6D6D6D">
                RAM <span style={{ color: "red" }}>*</span>
              </Text>
            </Flex>
            <Input
              mt="xs"
              value={ramState}
              onChange={(event) => setRamState(event.target.value)}
            />
            {fieldErrors.ram && (
              <Text c="red" size="12px">
                {fieldErrors.ram}
              </Text>
            )}
          </Box>
          <Box w="30%">
            <Flex gap="3px">
              <Text size="14px" fw="bold" c="#6D6D6D">
                Storage Capacity <span style={{ color: "red" }}>*</span>
              </Text>
            </Flex>
            <Input
              mt="xs"
              value={storageState}
              onChange={(event) => setStorageState(event.target.value)}
            />
            {fieldErrors.storage && (
              <Text c="red" size="12px">
                {fieldErrors.storage}
              </Text>
            )}
          </Box>
          <Box w="30%">
            <Flex gap="3px">
              <Text size="14px" fw="bold" c="#6D6D6D">
                Mac Address <span style={{ color: "red" }}>*</span>
              </Text>
            </Flex>
            <Input
              mt="xs"
              value={macAddressState}
              onChange={(event) => setMacAddressState(event.target.value)}
            />
            {fieldErrors.macAddress && (
              <Text c="red" size="12px">
                {fieldErrors.macAddress}
              </Text>
            )}
          </Box>
        </Flex>
        <Flex align="center" justify="space-around" mt="lg">
          <Box w="30%">
            <Flex gap="3px">
              <Text size="14px" fw="bold" c="#6D6D6D">
                Asset Owner <span style={{ color: "red" }}>*</span>
              </Text>
            </Flex>
            <Select
              data={assetOwners.map((owner) => ({
                value: owner.ownerName,
                label: owner.ownerName,
              }))}
              value={ownerNameState}
              defaultValue={ownerNameState}
              rightSection={<IconChevronDown />}
              onChange={(value) => setOwnerNameState(value)}
              mt="xs"
            />
          </Box>
          <Box w="30%">
            <Flex gap="3px">
              <Text size="14px" fw="bold" c="#6D6D6D">
                Ownership Type <span style={{ color: "red" }}>*</span>
              </Text>
            </Flex>
            {/* <Input
              mt="xs"
              value={ownershipTypeState}
              onChange={(event) => setOwnershipTypeState(event.target.value)}
            />
            {fieldErrors.ownershipType && (
              <Text c="red" size="12px">
                {fieldErrors.ownershipType}
              </Text>
            )} */}
            <Select
              mt="xs"
              data={["Owned", "Rented"]}
              defaultValue={ownershipTypeState}
              onChange={setOwnershipTypeState}
              required
              error={fieldErrors.ownershipType}
              allowDeselect={false}
              rightSection={<IconChevronDown />}
            />
          </Box>
          <Box w="30%">
            <Flex gap="3px">
              <Text size="14px" fw="bold" c="#6D6D6D">
                Cost <span style={{ color: "red" }}>*</span>
              </Text>
            </Flex>
            <Input
              mt="xs"
              value={costState}
              onChange={(event) => setCostState(event.target.value)}
            />
            {fieldErrors.cost && (
              <Text c="red" size="12px">
                {fieldErrors.cost}
              </Text>
            )}
          </Box>
        </Flex>
        <Box align="center" mt="md">
          <Box>
            <Box align="center">
              <Button
                mt="md"
                bg="#182452"
                onClick={handleSubmit}
                disabled={!saveButton}
              >
                Save Changes
              </Button>
            </Box>
          </Box>
          <Box
            ref={qrRef}
            style={{
              boxShadow: "0 4px 18px rgba(0, 0, 0, 0.1)",
              borderRadius: "5px",
              border: "1px solid #8080805E",
              padding: 10,
            }}
            w="20%"
            mt="lg"
          >
            <QrGeneratorEdit value={deviceId} />
          </Box>
        </Box>

        <Box align="center">
          <Button mt="md" bg="#182452" onClick={handlePrint}>
            Print
          </Button>
        </Box>
      </Flex>
    </div>
  );
};

export default EditDeviceModal;
