// import React from "react";
// import ReactApexChart from "react-apexcharts";

// const ApexChart = ({ data }) => {
//   const transformData = (data) => {
//     return data.map((item) => {
//       const [device, values] = Object.entries(item)[0];
//       return {
//         x: device,
//         y: parseInt(values["Total Devices"]),
//         goals: [
//           {
//             name: "Available Devices",
//             value: parseInt(values["Available Devices"]),
//             strokeHeight: 5,
//             strokeColor: "#775DD0",
//           },
//         ],
//       };
//     });
//   };

//   const seriesData = [
//     {
//       name: "Total Devices",
//       data: transformData(data),
//     },
//   ];

//   const options = {
//     chart: {
//       height: 350,
//       type: "bar",
//     },
//     plotOptions: {
//       bar: {
//         columnWidth: "40%",
//       },
//     },
//     colors: ["#00E396"],
//     dataLabels: {
//       enabled: false,
//     },
//     legend: {
//       show: true,
//       showForSingleSeries: true,
//       customLegendItems: ["Total Devices", "Available Devices"],
//       markers: {
//         fillColors: ["#00E396", "#775DD0"],
//       },
//     },
//     xaxis: {
//       type: "category",
//       labels: {
//         rotate: -45,
//         rotateAlways: true,
//       },
//     },
//   };

//   return (
//     <div>
//       <div id="chart">
//         <ReactApexChart
//           options={options}
//           series={seriesData}
//           type="bar"
//           height={350}
//         />
//       </div>
//       <div id="html-dist"></div>
//     </div>
//   );
// };

// export default ApexChart;

// import React from "react";
// import ReactApexChart from "react-apexcharts";

// const ApexChart = ({ data }) => {
//   const transformData = (data) => {
//     return data.map((item) => {
//       const [device, values] = Object.entries(item)[0];
//       return {
//         x: device,
//         y: parseInt(values["Total Devices"]),
//         goals: [
//           {
//             name: "Available Devices",
//             value: parseInt(values["Available Devices"]),
//             strokeHeight: 5,
//             strokeColor: "#775DD0",
//           },
//         ],
//       };
//     });
//   };

//   const seriesData = [
//     {
//       name: "Total Devices",
//       data: transformData(data),
//     },
//   ];

//   const options = {
//     chart: {
//       height: 350,
//       type: "bar",
//     },
//     plotOptions: {
//       bar: {
//         columnWidth: "40%",
//       },
//     },
//     colors: ["#00E396"],
//     dataLabels: {
//       enabled: false,
//     },
//     legend: {
//       show: true,
//       showForSingleSeries: true,
//       customLegendItems: ["Total Devices", "Available Devices"],
//       markers: {
//         fillColors: ["#00E396", "#775DD0"],
//       },
//     },
//     xaxis: {
//       type: "category",
//       labels: {
//         rotate: -45,
//         rotateAlways: true,
//       },
//     },
//   };

//   return (
//     <div>
//       <div id="chart">
//         <ReactApexChart
//           options={options}
//           series={seriesData}
//           type="bar"
//           height={350}
//         />
//       </div>
//       <div id="html-dist"></div>
//     </div>
//   );
// };

// export default ApexChart;

// import React from "react";
// import ReactApexChart from "react-apexcharts";

// const AvailableBarChart = ({ data }) => {
//   console.log(data);
//   const transformData = (data) => {
//     return data.map((item) => {
//       const [device, values] = Object.entries(item)[0];
//       return {
//         x: device,
//         y: parseInt(values["Total Devices"]),
//         goals: [
//           {
//             name: "Available Devices",
//             value: parseInt(values["Available Devices"]),
//             strokeHeight: 5,
//             strokeColor: "#775DD0",
//           },
//         ],
//       };
//     });
//   };

//   const seriesData = [
//     {
//       name: "Total Devices",
//       data: transformData(data),
//     },
//   ];

//   const options = {
//     chart: {
//       height: 350,
//       type: "bar",
//     },
//     plotOptions: {
//       bar: {
//         columnWidth: "40%",
//       },
//     },
//     colors: ["#00E396"],
//     dataLabels: {
//       enabled: false,
//     },
//     legend: {
//       show: true,
//       showForSingleSeries: true,
//       customLegendItems: ["Total Devices", "Available Devices"],
//       markers: {
//         fillColors: ["#00E396", "#775DD0"],
//       },
//     },
//     xaxis: {
//       type: "category",
//       labels: {
//         rotate: -45,
//         rotateAlways: true,
//       },
//     },
//   };

//   return (
//     <div>
//       <div id="chart">
//         <ReactApexChart
//           options={options}
//           series={seriesData}
//           type="bar"
//           height={350}
//         />
//       </div>
//       <div id="html-dist"></div>
//     </div>
//   );
// };

// export default AvailableBarChart;

// import React, { useState, useEffect } from "react";
// import ReactApexChart from "react-apexcharts";

// const ApexChart = () => {
//   const [chartState, setChartState] = useState({
//     series: [],
//     options: {
//       chart: {
//         type: "bar",
//         height: 350,
//       },
//       plotOptions: {
//         bar: {
//           horizontal: false,
//           columnWidth: "55%",
//           endingShape: "rounded",
//         },
//       },
//       dataLabels: {
//         enabled: false,
//       },
//       stroke: {
//         show: true,
//         width: 2,
//         colors: ["transparent"],
//       },
//       xaxis: {
//         categories: [],
//       },
//       yaxis: {
//         title: {
//           text: "Count",
//         },
//       },
//       fill: {
//         opacity: 1,
//       },
//       tooltip: {
//         y: {
//           formatter: function (val) {
//             return val;
//           },
//         },
//       },
//     },
//   });

//   useEffect(() => {
//     const data = [
//       { category: "Android_Mobile", total: 3, available: 2 },
//       { category: "iOS_Mobile", total: 2, available: 1 },
//       { category: "Laptop", total: 7, available: 6 },
//       { category: "Laptop", total: 7, available: 6 },
//       { category: "Laptop", total: 7, available: 6 },
//       { category: "Laptop", total: 7, available: 6 },
//       { category: "Laptop", total: 7, available: 6 },
//       { category: "Laptop", total: 7, available: 6 },
//       { category: "Laptop", total: 7, available: 6 },
//       { category: "Laptop", total: 7, available: 6 },
//       { category: "Laptop", total: 7, available: 6 },
//     ];

//     const series = [
//       {
//         name: "Total Devices",
//         data: data.map((item) => parseInt(item.total)),
//       },
//       {
//         name: "Available Devices",
//         data: data.map((item) => parseInt(item.available)),
//       },
//     ];

//     const categories = data.map((item) => item.category);

//     setChartState((prevState) => ({
//       ...prevState,
//       series,
//       options: {
//         ...prevState.options,
//         xaxis: {
//           categories,
//         },
//       },
//     }));
//   }, []);

//   return (
//     <div>
//       <div id="chart">
//         <ReactApexChart
//           options={chartState.options}
//           series={chartState.series}
//           type="bar"
//           height={350}
//         />
//       </div>
//       <div id="html-dist"></div>
//     </div>
//   );
// };

// export default ApexChart;

import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const AvailableBarChart = ({ data }) => {
  const [chartState, setChartState] = useState({
    series: [],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: "Count",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  });

  useEffect(() => {
    if (data) {
      const categories = data.map((item) => Object.keys(item)[0]); // Extract the category names
      const totalDevices = data.map((item) =>
        parseInt(item[Object.keys(item)[0]]["Total Devices"])
      );
      const availableDevices = data.map((item) =>
        parseInt(item[Object.keys(item)[0]]["Available Devices"])
      );

      const series = [
        {
          name: "Total Devices",
          data: totalDevices,
        },
        {
          name: "Available Devices",
          data: availableDevices,
        },
      ];

      setChartState((prevState) => ({
        ...prevState,
        series,
        options: {
          ...prevState.options,
          xaxis: {
            categories,
          },
        },
      }));
    }
  }, [data]);

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={chartState.options}
          series={chartState.series}
          type="bar"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default AvailableBarChart;
