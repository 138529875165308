import React, { useEffect, useState } from "react";
import TotalCostChart from "./TotalCostChart"; // Ensure this path is correct
import { Box } from "@mantine/core";

const CalculateTotalCost = () => {
  const [data, setData] = useState({});
  const [totalCost, setTotalCost] = useState({});
  const [chartData, setChartData] = useState({ series: [], labels: [] });
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      const response = await fetch(`${API_BASE_URL}`);
      const result = await response.json();
      setData(result);
    };

    const delayFetch = () => {
      setTimeout(() => {
        fetchData();
      }, 800);
    };

    delayFetch();
  }, []);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      calculateTotalCost(data);
    }
  }, [data]);

  const calculateTotalCost = (data) => {
    const categoryCost = {};
    const series = [];
    const labels = [];

    for (const category in data) {
      if (data.hasOwnProperty(category)) {
        let total = 0;
        data[category].forEach((platform) => {
          platform.Devices.forEach((device) => {
            device.DeviceList.forEach((deviceItem) => {
              total += parseFloat(deviceItem.cost);
            });
          });
        });
        categoryCost[category] = total;
        series.push(total);
        labels.push(category);
      }
    }

    setTotalCost(categoryCost);
    setChartData({ series, labels });
  };

  return (
    <div>
      <Box w="95%">
        <TotalCostChart series={chartData.series} labels={chartData.labels} />
      </Box>
    </div>
  );
};

export default CalculateTotalCost;
