import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

export default function TotalCostChart({ series, labels }) {
  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return "₹" + " " + val;
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    xaxis: {
      categories: labels,
      position: "bottom",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: function (val) {
          return "₹" + " " + val;
        },
      },
    },
    // title: {
    //   text: "",
    //   floating: true,
    //   offsetY: 0,
    //   align: "center",
    //   style: {
    //     color: "#444",
    //   },
    // },
  });

  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: labels,
      },
    }));
  }, [labels]);

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={[{ name: "Total Cost", data: series }]}
          type="bar"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
}



// import React from "react";
// import ReactApexChart from "react-apexcharts";

// const ApexChart = () => {
//   const [chartData] = React.useState({
//     series: [
//       {
//         name: "Inflation",
//         data: [
//           2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2, 4.0,
//           10.1, 4.0, 3.6, 3.2, 4.0, 10.1, 4.0, 3.6, 3.2,
//         ],
//       },
//     ],
//     options: {
//       chart: {
//         height: 350,
//         type: "bar",
//       },
//       plotOptions: {
//         bar: {
//           borderRadius: 10,
//           dataLabels: {
//             position: "top", // top, center, bottom
//           },
//         },
//       },
//       dataLabels: {
//         enabled: true,
//         formatter: function (val) {
//           return val + "%";
//         },
//         offsetY: -20,
//         style: {
//           fontSize: "12px",
//           colors: ["#304758"],
//         },
//       },
//       xaxis: {
//         categories: [
//           "Jan",
//           "Feb",
//           "Mar",
//           "Apr",
//           "May",
//           "Jun",
//           "Jul",
//           "Aug",
//           "Sep",
//           "Oct",
//           "Nov",
//           "Dec",
//         ],
//         position: "top",
//         axisBorder: {
//           show: false,
//         },
//         axisTicks: {
//           show: false,
//         },
//         crosshairs: {
//           fill: {
//             type: "gradient",
//             gradient: {
//               colorFrom: "#D8E3F0",
//               colorTo: "#BED1E6",
//               stops: [0, 100],
//               opacityFrom: 0.4,
//               opacityTo: 0.5,
//             },
//           },
//         },
//         tooltip: {
//           enabled: true,
//         },
//       },
//       yaxis: {
//         axisBorder: {
//           show: false,
//         },
//         axisTicks: {
//           show: false,
//         },
//         labels: {
//           show: false,
//           formatter: function (val) {
//             return val + "%";
//           },
//         },
//       },
//       title: {
//         text: "Monthly Inflation in Argentina, 2002",
//         floating: true,
//         offsetY: 330,
//         align: "center",
//         style: {
//           color: "#444",
//         },
//       },
//     },
//   });

//   return (
//     <div>
//       <div id="chart">
//         <ReactApexChart
//           options={chartData.options}
//           series={chartData.series}
//           type="bar"
//           height={350}
//         />
//       </div>
//       <div id="html-dist"></div>
//     </div>
//   );
// };

// export default ApexChart;