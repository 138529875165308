import { Image } from "@mantine/core";
import React from "react";
import QRCode from "react-qr-code";
import DeafultQRImage from "../../asset/QR_Code.png";

const QrcodeGenerator = ({ value }) => {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center", padding: 10 }}>
        {value ? (
          <QRCode value={value} size={200} />
        ) : (
          <Image
            src={"https://via.placeholder.com/200?text=QR+Code"}
            width={200}
            height={200}
            alt="Default QR"
          />
        )}
      </div>
    </div>
  );
};

export default QrcodeGenerator;
