import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./login.module.css";
import logo from "../../asset/logo.png";
import abstract from "../../asset/abstract.png";
import { useAuth } from "../../context/AuthContext";

const Login = ({ onLoginSuccess }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authStatus, setAuthStatus] = useState(null);
  const { login } = useAuth();
  const navigate = useNavigate();

  const authenticateUser = async () => {
    try {
      const response = await fetch(
        "https://assetmanager.igsqa.com/api/auth/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email, password: password }),
        }
      );

      const data = await response.json();
      // console.log("Response data:", data);
      // console.log("Role:", data.Data.Role);

      if (response.ok && data.Status === "OK") {
        setAuthStatus("success");
        onLoginSuccess(data); // Pass the role to the onLoginSuccess callback
        login(data);

        navigate("/book-device");
      } else {
        setAuthStatus("failure");
      }
    } catch (error) {
      console.error("Error during authentication:", error);
      setAuthStatus("failure");
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    await authenticateUser();
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftPanel}>
        <div className={styles.text}>Where amazing things happen</div>
        <div className={styles.illustrations}>
          <div className={styles.illustrationItem}>
            <img src={abstract} alt="Graph" />
          </div>
        </div>
      </div>
      <div className={styles.rightPanel}>
        <div className={styles.logo}>
          <img src={logo} alt="Logo" />
          <h1>IGS Asset Manager</h1>
        </div>
        <form className={styles.form} onSubmit={handleLogin}>
          <div className={styles.inputGroup}>
            <label>Email</label>
            <input
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className={styles.inputGroup}>
            <label>Password</label>
            <input
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className={styles.forgotPassword}>
            <a href="/">Forgot password?</a>
          </div>
          <button type="submit" className={styles.loginButton}>
            Login
          </button>
          {authStatus === "failure" && (
            <div className={styles.errorMessage}>
              Authentication failed. Please check your credentials.
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Login;
