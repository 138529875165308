import { Image } from "@mantine/core";
import React from "react";
import QRCode from "react-qr-code";
import DeafultQRImage from "../../asset/QR_Code.png";

const QrcodeGenerator = ({ value }) => {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center", padding: 10 }}>
        <QRCode value={value} size={200} />
      </div>
    </div>
  );
};

export default QrcodeGenerator;
