// import {
//   Box,
//   Divider,
//   Flex,
//   ScrollArea,
//   Text,
//   Pagination,
// } from "@mantine/core";
// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";

// export default function RejectedRequest({ searchQuery }) {
//   const navigate = useNavigate();
//   const [rejectedRequests, setRejectedRequests] = useState([]);
// const [currentPage, setCurrentPage] = useState(1);
// const itemsPerPage = 10; // Adjust the number of items per page as needed

//   useEffect(() => {
//     const fetchRejectedRequests = async () => {
//       try {
//         const response = await axios.get(
//           "https://d13d04nxcwaid6.cloudfront.net/api/v1/admin/devices/rejected-history"
//         );

//         // Assuming response.data contains the actual array of rejected requests
//         const data = response.data.returnRejectedDevices.map((request) => ({
//           category: request.categoryName,
//           platform: request.platformName,
//           deviceName: request.deviceName,
//           assigneeName: `${request.bookHistory[0].First_Name} ${request.bookHistory[0].Last_Name}`,
//           date: request.bookHistory[0].dateOfRejection
//             ? new Date(
//                 request.bookHistory[0].dateOfRejection
//               ).toLocaleDateString()
//             : "N/A",
//           status: request.status.includes("rejected") ? "Rejected" : "Other",
//           did: request.did,
//         }));
//         console.log("Mapped Data:", data);
//         setRejectedRequests(data);
//       } catch (error) {
//         console.error("Error fetching rejected requests:", error);
//       }
//     };

//     const timeoutId = setTimeout(() => {
//       fetchRejectedRequests();
//     }, 1200);

//     return () => clearTimeout(timeoutId);
//   }, []);

// const totalItems = rejectedRequests.length;
// const totalPages = Math.ceil(totalItems / itemsPerPage);
// const currentItems = rejectedRequests.slice(
//   (currentPage - 1) * itemsPerPage,
//   currentPage * itemsPerPage
// );

// const handlePageChange = (page) => {
//   setCurrentPage(page);
// };

// const filteredData = currentItems.filter((request) =>
//   Object.values(request)
//     .join(" ")
//     .toLowerCase()
//     .includes(searchQuery.toLowerCase())
// );

//   return (
//     <div>
//       <Box
//         m="10px"
//         p="10px"
//         bg="#182452"
//         style={{
//           border: "1px solid #e0e0e0",
//           borderRadius: "4px",
//           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//         }}
//       >
//         <Flex>
//           <Text w={"20%"} fw={"bold"} c="#FFFFFF" className="text">
//             Category
//           </Text>
//           <Text w={"20%"} fw={"bold"} c="#FFFFFF" className="text">
//             Platform
//           </Text>
//           <Text w={"20%"} fw={"bold"} c="#FFFFFF" className="text">
//             Device Name
//           </Text>
//           <Text w={"20%"} fw={"bold"} c="#FFFFFF" className="text">
//             Assignee Name
//           </Text>
//           <Text w={"20%"} fw={"bold"} c="#FFFFFF" className="text">
//             Rejected Date
//           </Text>
//         </Flex>
//       </Box>
//       <Divider my="sm" />
//       <ScrollArea style={{ height: "calc(100vh - 310px)" }}>
//         {filteredData.length > 0 ? (
//           filteredData.map((request, index) => (
//             <Box
//               key={index}
//               style={{
//                 border: "1px solid #e0e0e0",
//                 borderRadius: "4px",
//                 cursor: "pointer",
//                 boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//               }}
//               m="10px"
//               p="10px"
//               className="DeviceList"
//               onClick={() =>
//                 navigate(`/Requests/ViewRejectedRequest/${request.did}`)
//               }
//             >
//               <Flex>
//                 <Text w={"20%"} c="#393939BA" fw={"bold"} className="text">
//                   {request.category}
//                 </Text>
//                 <Text w={"20%"} c="#393939BA" fw={"bold"} className="text">
//                   {request.platform}
//                 </Text>
//                 <Text w={"20%"} c="#393939BA" fw={"bold"} className="text">
//                   {request.deviceName}
//                 </Text>
//                 <Text w={"20%"} c="#393939BA" fw={"bold"} className="text">
//                   {request.assigneeName}
//                 </Text>
//                 <Text w={"20%"} c="#393939BA" fw={"bold"} className="text">
//                   {request.date}
//                 </Text>
//               </Flex>
//             </Box>
//           ))
//         ) : (
//           <Text
//             m="10px"
//             p="10px"
//             c="#393939BA"
//             fw={"bold"}
//             align="center"
//             size="20px"
//             mt="50px"
//           >
//             No rejected requests
//           </Text>
//         )}
// <Flex justify="center" mt="lg">
//   <Pagination
//     page={currentPage}
//     onChange={handlePageChange}
//     total={totalPages}
//     color="#182452"
//   />
// </Flex>
//       </ScrollArea>
//     </div>
//   );
// }

import {
  Box,
  Divider,
  Flex,
  Pagination,
  ScrollArea,
  Text,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function RejectedRequest({ searchQuery }) {
  const navigate = useNavigate();
  const [rejectedRequests, setRejectedRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchRejectedRequests = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/devices/rejected-history`,
          {
            headers: {},
          }
        );
        const data = await response.json();
        setRejectedRequests(data.returnRejectedDevices || []);
      } catch (error) {
        console.error("Error fetching rejected requests:", error);
      }
    };

    const timeoutId = setTimeout(() => {
      fetchRejectedRequests();
    }, 1200);

    return () => clearTimeout(timeoutId);
  }, []);

  const totalItems = rejectedRequests.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentItems = rejectedRequests.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const filteredData = currentItems.filter((request) =>
    Object.values(request)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <Box
        m="10px"
        p="10px"
        mr="sm"
        ml="sm"
        bg="#182452"
        style={{
          border: "1px solid #e0e0e0",
          borderRadius: "4px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Flex>
          <Text w={"20%"} fw={"bold"} c="#FFFFFF" className="text">
            Category
          </Text>
          <Text w={"20%"} fw={"bold"} c="#FFFFFF" className="text">
            Platform
          </Text>
          <Text w={"20%"} fw={"bold"} c="#FFFFFF" className="text">
            Device Name
          </Text>
          <Text w={"20%"} fw={"bold"} c="#FFFFFF" className="text">
            Assignee Name
          </Text>
          <Text w={"20%"} fw={"bold"} c="#FFFFFF" className="text">
            Rejected Date
          </Text>
        </Flex>
      </Box>
      <Divider my="sm" />
      <ScrollArea style={{ height: "calc(100vh - 310px)" }}>
        {filteredData.map((request, index) => {
          const lastHistory =
            request.bookHistory[request.bookHistory.length - 1];
          return (
            <Box
              key={index}
              style={{
                border: "1px solid #e0e0e0",
                borderRadius: "4px",
                cursor: "pointer",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
              m="10px"
              p="10px"
              mr="sm"
              ml="sm"
              className="DeviceList"
              onClick={() =>
                navigate(`/Requests/ViewRejectedRequest/${request.did}`)
              }
            >
              <Flex>
                <Text w={"20%"} c="#393939BA" fw={"bold"} className="text">
                  {request.categoryName}
                </Text>
                <Text w={"20%"} c="#393939BA" fw={"bold"} className="text">
                  {request.platformName}
                </Text>
                <Text w={"20%"} c="#393939BA" fw={"bold"} className="text">
                  {request.deviceName}
                </Text>
                <Text w={"20%"} c="#393939BA" fw={"bold"} className="text">
                  {lastHistory.First_Name + " " + lastHistory.Last_Name}
                </Text>
                <Text w={"20%"} c="#393939BA" fw={"bold"} className="text">
                  {new Date(lastHistory.dateOfRejection).toLocaleDateString()}
                </Text>
              </Flex>
            </Box>
          );
        })}
        <Flex justify="center" mt="lg">
          <Pagination
            page={currentPage}
            onChange={handlePageChange}
            total={totalPages}
            color="#182452"
          />
        </Flex>
      </ScrollArea>
    </div>
  );
}
