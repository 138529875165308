import React, { useState } from "react";
import { Scanner } from "@yudiel/react-qr-scanner";

export default function QrCodeScanner({ onScan }) {
  const [data, setData] = useState("");

  const handleScan = (result) => {
    if (result && result.length > 0) {
      setData(result[0].rawValue);
      onScan(result[0].rawValue);
    }
  };

  const scannerStyles = {
    container: {
      width: "calc(100vh - 400px)", // Adjust the width as needed
      height: "calc(100vh - 400px)", // Adjust the height as needed
      position: "relative", // Ensure the scanner is positioned correctly
    },
    video: {
      width: "100%",
      height: "100%",
      objectFit: "cover", // Ensures the video covers the container
    },
  };

  return (
    <div>
      <Scanner onScan={handleScan} styles={scannerStyles} />

      {/* <div>
        <h3>Scan Result:</h3>
        <p>{data}</p>
      </div> */}
    </div>
  );
}
