// import {
//   Box,
//   Button,
//   Flex,
//   Input,
//   Modal,
//   Paper,
//   Select,
//   Text,
// } from "@mantine/core";
// import React, { useState, useEffect } from "react";
// import QRcodeScanner from "./QRcodeScanner";
// import { useNavigate, useParams } from "react-router-dom";
// import { useDisclosure } from "@mantine/hooks";
// import { useAuth } from "../../context/AuthContext";
// import { IconChevronDown } from "@tabler/icons-react";
// function getDate() {
//   const today = new Date();
//   const month = today.getMonth() + 1;
//   const year = today.getFullYear();
//   const date = today.getDate();
//   return `${date} / ${month} / ${year}`;
// }

// export default function ScanDevice() {
//   const navigate = useNavigate();
//   const [projects, setProjects] = useState([]);
//   const { user } = useAuth();
//   const { selectedCategory, platform, deviceName } = useParams();
//   const [currentDate, setCurrentDate] = useState(getDate());
//   const [scannedData, setScannedData] = useState(null);
//   const [inputData, setInputData] = useState(null);
//   const [matchedDevice, setMatchedDevice] = useState(null);
//   const [bookingSuccess, setBookingSuccess] = useState(false);
//   const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
//   const [opened, { open, close }] = useDisclosure(false);

//   useEffect(() => {
//     if (!scannedData) return;

//     const fetchDeviceDetails = async () => {
//       try {
//         const response = await fetch(
//           `${API_BASE_URL}/devices/get/cat/${selectedCategory}/${platform}/${deviceName}`
//         );
//         const data = await response.json();
//         console.log("bob");
//         const matchedDevice = data.find((device) => device.did === scannedData);

//         if (matchedDevice) {
//           setMatchedDevice(matchedDevice);
//           bookDevice(matchedDevice.did);
//         } else {
//           setMatchedDevice(null);
//           setBookingSuccess(false);
//           open();
//         }
//       } catch (error) {
//         console.error("Error fetching device details:", error);
//         setBookingSuccess(false);
//         open();
//       }
//     };

//     fetchDeviceDetails();
//   }, [scannedData, selectedCategory, platform, deviceName, API_BASE_URL, open]);

//   useEffect(() => {
//     const fetchProjects = async () => {
//       try {
//         const response = await fetch(`${API_BASE_URL}/getallprojects`);
//         const data = await response.json();
//         // Transform data into the format expected by the Select component
//         const projectNames = data.map((project) => project.projectName);
//         setProjects(projectNames);
//       } catch (error) {
//         console.error("Error fetching project data:", error);
//       }
//     };

//     fetchProjects();
//   }, []);
//   const bookDevice = async (deviceId) => {
//     try {
//       console.log("bob is here...");
//       const response = await fetch(`${API_BASE_URL}/devices/request-book`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           did: deviceId,
//           userId: user.Data.Email,
//           dateofbooking: currentDate,
//         }),
//       });
//       console.log(response);
//       if (response.ok) {
//         setBookingSuccess(true);
//       } else {
//         setBookingSuccess(false);
//         console.error("Error booking the device");
//       }
//     } catch (error) {
//       setBookingSuccess(false);
//       console.error("Error booking the device:", error);
//     } finally {
//       open(); // Open modal regardless of booking success
//     }
//   };

//   const handleScan = (deviceId) => {
//     setScannedData(deviceId);
//   };
//   const bookDeviceInput = async (inputData) => {
//     try {
//       const response = await fetch(`${API_BASE_URL}/devices/request-book`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           did: inputData,
//           userId: user.Data.Email,
//           dateofbooking: currentDate,
//         }),
//       });

//       console.log(response);
//       if (response.ok) {
//         setBookingSuccess(true);
//       } else {
//         setBookingSuccess(false);
//         console.error("Error booking the device");
//       }
//     } catch (error) {
//       setBookingSuccess(false);
//       console.error("Error booking the device:", error);
//     } finally {
//       open();
//     }
//   };

//   return (
//     <div>
//       <Paper bg="#FFFFF" m="md" h="91vh">
//         <Flex justify="center" align="center">
//           <Box w="65%" align="center" pt="xl">
//             <Text c="#DC8585" size="xl" fs="italic" fw="bold">
//               Scan your Device's QR Code to Book
//             </Text>
//             <Box w="55%" mt="-20px">
//               <QRcodeScanner onScan={handleScan} />
//               <Text mt="md" c="#00000080" fw="bold" size="20px">
//                 OR
//               </Text>
//             </Box>
//           </Box>
//           <Box w="35%" p="xl">
//             <Box
//               p="50px"
//               bg="#EFEFEF"
//               style={{
//                 boxShadow: "0 4px 18px rgba(0, 0, 0, 0.1)",
//                 borderRadius: "5px",
//                 border: "1px solid #8080805E",
//               }}
//             >
//               <Box>
//                 <Box>
//                   <Text c="#00000080" fw="bold">
//                     Category
//                   </Text>
//                   <Box
//                     w="100%"
//                     bg="#FFFFFF"
//                     py="5px"
//                     px="10px"
//                     style={{ border: "1px solid #8080805E", borderRadius: 5 }}
//                   >
//                     <Text>{selectedCategory}</Text>
//                   </Box>
//                 </Box>
//                 <Box mt="md">
//                   <Text c="#00000080" fw="bold">
//                     Platform
//                   </Text>
//                   <Box
//                     w="100%"
//                     bg="#FFFFFF"
//                     py="5px"
//                     px="10px"
//                     style={{ border: "1px solid #8080805E", borderRadius: 5 }}
//                   >
//                     <Text>{platform}</Text>
//                   </Box>
//                 </Box>
//                 <Box mt="md">
//                   <Text c="#00000080" fw="bold">
//                     Device Name
//                   </Text>
//                   <Box
//                     w="100%"
//                     bg="#FFFFFF"
//                     py="5px"
//                     px="10px"
//                     style={{ border: "1px solid #8080805E", borderRadius: 5 }}
//                   >
//                     <Text>{deviceName}</Text>
//                   </Box>
//                 </Box>
//                 <Box mt="md">
//                   <Text c="#00000080" fw="bold">
//                     Start Date
//                   </Text>
//                   <Box
//                     w="100%"
//                     bg="#FFFFFF"
//                     py="5px"
//                     px="10px"
//                     style={{ border: "1px solid #8080805E", borderRadius: 5 }}
//                   >
//                     <Text>{currentDate}</Text>
//                   </Box>
//                 </Box>
//                 <Box mt="md">
//                   <Text c="#00000080" fw="bold">
//                     Select Project <span style={{ color: "red" }}>*</span>
//                   </Text>
//                   <Select data={projects} rightSection={<IconChevronDown />} />
//                 </Box>
//               </Box>
//             </Box>
//           </Box>
//         </Flex>
//         <Flex justify="space-between" mt="xl">
//           <Box w="65%" align="center">
//             <Text c="#00000080" fw="bold" size="18px">
//               Enter Device ID
//             </Text>
//             <Box
//               w="50%"
//               mt="md"
//               style={{
//                 boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                 border: "1px solid #8080805E",
//                 overflow: "hidden",
//                 borderRadius: "5px",
//               }}
//             >
//               <Input
//                 variant="filled"
//                 onChange={(e) => setInputData(e.target.value)}
//               />
//             </Box>
//           </Box>
//           <Flex align="end" gap="xl" pr={"xl"}>
//             <Button bg="red" onClick={() => navigate(-1)}>
//               Cancel
//             </Button>
//             <Button bg="#182452" onClick={() => bookDeviceInput(inputData)}>
//               Book
//             </Button>
//           </Flex>
//         </Flex>
//         <Modal opened={opened} onClose={close} centered withCloseButton={false}>
//           <Box m="xl" align="center">
//             {!bookingSuccess ? (
//               <Box>
//                 <Text c="#00000080" fw="bold" size="18px">
//                   No matching device found
//                 </Text>
//                 <Flex mt="xl" w="100%" justify="center" gap="lg">
//                   <Button bg="red" onClick={() => navigate(-1)}>
//                     Cancel
//                   </Button>
//                   <Button bg="#182452" onClick={close}>
//                     Retry
//                   </Button>
//                 </Flex>
//               </Box>
//             ) : (
//               <Box>
//                 <Text c="#00000080" fw="bold" size="18px">
//                   Device Requested!
//                 </Text>
//                 <Button onClick={() => navigate(-1)} mt="xl" bg="#182452">
//                   OK
//                 </Button>
//               </Box>
//             )}
//           </Box>
//         </Modal>
//       </Paper>
//     </div>
//   );
// }

import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Modal,
  Paper,
  ScrollArea,
  Select,
  Text,
} from "@mantine/core";
import React, { useState, useEffect } from "react";
import QRcodeScanner from "./QRcodeScanner";
import { useNavigate, useParams } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { useAuth } from "../../context/AuthContext";
import { IconChevronDown } from "@tabler/icons-react";

function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  return `${date} / ${month} / ${year}`;
}

export default function ScanDevice() {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const { user } = useAuth();
  const { selectedCategory, platform, deviceName } = useParams();
  const [currentDate, setCurrentDate] = useState(getDate());
  const [scannedData, setScannedData] = useState(null);
  const [inputData, setInputData] = useState(null);
  const [matchedDevice, setMatchedDevice] = useState(null);
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [opened, { open, close }] = useDisclosure(false);
  const [checked, setChecked] = useState(true);
  useEffect(() => {
    if (!scannedData) return;

    const fetchDeviceDetails = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/devices/get/cat/${selectedCategory}/${platform}/${deviceName}`
        );
        const data = await response.json();
        console.log("bob");
        const matchedDevice = data.find((device) => device.did === scannedData);

        if (matchedDevice) {
          setMatchedDevice(matchedDevice);
          bookDevice(matchedDevice.did);
        } else {
          setMatchedDevice(null);
          setBookingSuccess(false);
          open();
        }
      } catch (error) {
        console.error("Error fetching device details:", error);
        setBookingSuccess(false);
        open();
      }
    };

    fetchDeviceDetails();
  }, [scannedData, selectedCategory, platform, deviceName, API_BASE_URL, open]);

  const handleCheckboxChange = (event) => {
    setChecked(event.currentTarget.checked);
  };
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/getallprojects`);
        const data = await response.json();
        // Transform data into the format expected by the Select component
        const projectNames = data.map((project) => project.projectName);
        setProjects(projectNames);
        // Set the default selected project to the first element
        if (projectNames.length > 0) {
          setSelectedProject(projectNames[0]);
        }
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };

    fetchProjects();
  }, []);

  const bookDevice = async (deviceId) => {
    try {
      console.log("bob is here...");
      const response = await fetch(`${API_BASE_URL}/devices/request-book`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          did: deviceId,
          userId: user.Data.Email,
          dateofbooking: currentDate,
          projectName: selectedProject, // Include the selected project
        }),
      });
      console.log(response);
      if (response.ok) {
        setBookingSuccess(true);
      } else {
        setBookingSuccess(false);
        console.error("Error booking the device");
      }
    } catch (error) {
      setBookingSuccess(false);
      console.error("Error booking the device:", error);
    } finally {
      open(); // Open modal regardless of booking success
    }
  };

  const handleScan = (deviceId) => {
    setScannedData(deviceId);
  };

  const bookDeviceInput = async (inputData) => {
    try {
      const response = await fetch(`${API_BASE_URL}/devices/request-book`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          did: inputData,
          userId: user.Data.Email,
          dateofbooking: currentDate,
          projectName: selectedProject, // Include the selected project
        }),
      });

      console.log(response);
      if (response.ok) {
        setBookingSuccess(true);
      } else {
        setBookingSuccess(false);
        console.error("Error booking the device");
      }
    } catch (error) {
      setBookingSuccess(false);
      console.error("Error booking the device:", error);
    } finally {
      open();
    }
  };

  return (
    <div>
      <ScrollArea h="calc(100vh - 50px)">
        <Paper bg="#FFFFF" m="md" pb="xl">
          <Flex justify="center" align="center" p="md">
            <Box w="60%" align="center">
              <Text c="#DC8585" size="xl" fs="italic" fw="bold">
                Scan your Device's QR Code to Book
              </Text>
              <Box w="55%" mt="md">
                {checked && <QRcodeScanner onScan={handleScan} />}
                <Text mt="md" c="#00000080" fw="bold" size="20px">
                  OR
                </Text>
              </Box>
            </Box>
            <Box w="40%">
              <Box w="80%">
                <Box
                  p="30px"
                  bg="#EFEFEF"
                  style={{
                    boxShadow: "0 4px 18px rgba(0, 0, 0, 0.1)",
                    borderRadius: "5px",
                    border: "1px solid #8080805E",
                  }}
                >
                  <Box>
                    <Box>
                      <Text c="#00000080" fw="bold">
                        Category
                      </Text>
                      <Box
                        w="100%"
                        bg="#FFFFFF"
                        py="5px"
                        px="10px"
                        style={{
                          border: "1px solid #8080805E",
                          borderRadius: 5,
                        }}
                      >
                        <Text>{selectedCategory}</Text>
                      </Box>
                    </Box>
                    <Box mt="md">
                      <Text c="#00000080" fw="bold">
                        Platform
                      </Text>
                      <Box
                        w="100%"
                        bg="#FFFFFF"
                        py="5px"
                        px="10px"
                        style={{
                          border: "1px solid #8080805E",
                          borderRadius: 5,
                        }}
                      >
                        <Text>{platform}</Text>
                      </Box>
                    </Box>
                    <Box mt="md">
                      <Text c="#00000080" fw="bold">
                        Device Name
                      </Text>
                      <Box
                        w="100%"
                        bg="#FFFFFF"
                        py="5px"
                        px="10px"
                        style={{
                          border: "1px solid #8080805E",
                          borderRadius: 5,
                        }}
                      >
                        <Text>{deviceName}</Text>
                      </Box>
                    </Box>
                    <Box mt="md">
                      <Text c="#00000080" fw="bold">
                        Start Date
                      </Text>
                      <Box
                        w="100%"
                        bg="#FFFFFF"
                        py="5px"
                        px="10px"
                        style={{
                          border: "1px solid #8080805E",
                          borderRadius: 5,
                        }}
                      >
                        <Text>{currentDate}</Text>
                      </Box>
                    </Box>
                    <Box mt="md">
                      <Text c="#00000080" fw="bold">
                        Select Project <span style={{ color: "red" }}>*</span>
                      </Text>
                      <Select
                        data={projects}
                        value={selectedProject}
                        onChange={setSelectedProject}
                        rightSection={<IconChevronDown />}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box align="center" mt="md">
                <Checkbox
                  checked={checked}
                  onChange={handleCheckboxChange}
                  label="I Agree to the Terms & Conditions"
                  color="#182452"
                  ml="md"
                />
              </Box>
            </Box>
          </Flex>
          <Flex justify="space-between" mt="xl">
            <Box w="65%" align="center">
              <Text c="#00000080" fw="bold" size="18px">
                Enter Device ID
              </Text>
              <Box
                w="50%"
                mt="md"
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #8080805E",
                  overflow: "hidden",
                  borderRadius: "5px",
                }}
              >
                <Input
                  variant="filled"
                  onChange={(e) => setInputData(e.target.value)}
                />
              </Box>
            </Box>
            <Flex align="end" gap="xl" pr={"xl"}>
              <Button bg="red" onClick={() => navigate(-1)}>
                Cancel
              </Button>
              <Button
                bg="#182452"
                onClick={() => bookDeviceInput(inputData)}
                disabled={!checked}
              >
                Book
              </Button>
            </Flex>
          </Flex>
          <Modal
            opened={opened}
            onClose={close}
            centered
            withCloseButton={false}
          >
            <Box m="xl" align="center">
              {!bookingSuccess ? (
                <Box>
                  <Text c="#00000080" fw="bold" size="18px">
                    No matching device found
                  </Text>
                  <Flex mt="xl" w="100%" justify="center" gap="lg">
                    <Button bg="red" onClick={() => navigate(-1)}>
                      Cancel
                    </Button>
                    <Button bg="#182452" onClick={close}>
                      Retry
                    </Button>
                  </Flex>
                </Box>
              ) : (
                <Box>
                  <Text c="#00000080" fw="bold" size="18px">
                    Device Requested!
                  </Text>
                  <Button onClick={() => navigate(-1)} mt="xl" bg="#182452">
                    OK
                  </Button>
                </Box>
              )}
            </Box>
          </Modal>
        </Paper>
      </ScrollArea>
    </div>
  );
}
